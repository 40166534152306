<template>
  <div class="mb-4 card"> <!--mb-4 is medium sized bottom margin. Card is a bootstrap class for a 'card'-->
    <div class="p-3 card-body"> <!-- This defines the body of the card, p-3 is moderate padding-->
      <div class="d-flex" :class="directionReverse ? reverseDirection : ''"> <!--This class is used for creating a flexible layout, often used in combination with Bootstrap's grid system.
Inside this flex container, there are two parts:Icon and content-->
        <div> <!-- this is icon-->
          <div class="text-center icon icon-shape" 
           :class="`${iconBackground} ${this.$store.state.isRTL ? 'border-radius-md' : 'border-radius-2xl'}`">
            <i class="text-lg opacity-10" :class="iconClass" aria-hidden="true"></i>
          </div> 
        </div>
        <div :class="contentClass"> <!-- this is content-->
          <div class="numbers" v-if="this.$store.state.isRTL"> <!-- if right to left arabic reading-->
            <p class="mb-0 text-sm text-uppercase font-weight-bold" :class="titleColor">{{ title }}</p> <!-- title-->
            <h5 class="font-weight-bolder" :class="valueColor">{{ value }} <!-- showing value string here $54,000-->
            <span class="text-sm"  :class="percentageColor">{{ percentage }}</span> <!-- showing percentage text here-->
            <span class="font-weight-light text-sm"> {{detail}}</span> <!-- showing detail here-->
            </h5>
          </div>
          <div class="numbers" v-else> <!-- this is if reading from left to right, english reading-->
            <p class="mb-0 text-sm text-uppercase font-weight-bold" :class="titleColor">{{ title }}</p>
            <h5 class="font-weight-bolder" :class="valueColor"> {{ value }}</h5>
            <span class="text-sm"  :class="percentageColor">{{ percentage }}</span> 
            {{detail}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    directionReverse: Boolean,
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    value: {
      required: false,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
  },
};
</script>
