// AIAnalysisStore.js
import { defineStore } from 'pinia';
import { updateUserInFirestore, getUserFromFirestore } from '../dbService';

export const useAIAnalysisStore = defineStore('aiAnalysis', {
  state: () => ({
    aiAnalysisResult: '',
  }),

  actions: {
    async saveAIAnalysisToFirestore(userId, analysisResult) {
      try {
        const updateData = { aiAnalysisResult: analysisResult };
        await updateUserInFirestore(userId, updateData);
        
      } catch (error) {
        console.error("Error saving AI analysis data to Firestore: ", error);
      }
    },

    async fetchAIAnalysisFromFirestore(userId) {
      try {
        const userData = await getUserFromFirestore(userId);
        if (userData && userData.aiAnalysisResult) {
          this.aiAnalysisResult = userData.aiAnalysisResult;
          return this.aiAnalysisResult; // Return the result
        }
        return null; // Return null if no result is found
      } catch (error) {
        console.error("Error fetching AI analysis data from Firestore: ", error);
        return null; // Return null on error
      }
    },
  }
});