import { defineStore } from 'pinia';
import {useGlobalStore} from '../store/globalStore';
import { updateUserInFirestore, getUserFromFirestore } from '../dbService';
import { auth } from '../main'; // Import Firebase auth to check login status

export const useIncomeStore = defineStore('income', {
  state: () => { // defined incomeList array to store data
    const globe = useGlobalStore();
    return {
      incomeList: [
        {
          id: 1,
          name: "Salary",
          amount: 50000,
          frequency: "Monthly",
          from: globe.currentAge,
          till: globe.retirementAge,
          change: 10,
          changetype: "Increase",
          icon: "fa fa-briefcase",
          earner: "Me",
          type: "salary"
        },
        {
          id: 2,
          name: "Bonus",
          amount: 100000,
          frequency: "Annual",
          from: globe.currentAge,
          till: globe.retirementAge,
          change: 5,
          changetype: "Increase",
          icon: "ni ni-briefcase-24",
          earner: "Me",
          type: "bonus"
        },
      ]
    }
  },

  actions: {
    async addIncome(income) { // to add incoming data in incomeList array. this function is called from ModalSalary
      this.incomeList.push(income);

      // Check if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          await this.saveIncomeToFirestore(user.uid); // Autosave the updated income list by calling function, mentioned below
        } catch (error) {
          console.error("Error autosaving income: ", error);
        }
      }
    },
    async updateIncome(updatedIncome) { // to update income

      const index = this.incomeList.findIndex(income => income.id === updatedIncome.id);
      if (index !== -1) {
        this.incomeList[index] = updatedIncome;


        // Log the updated from and till values
        console.log(`Updated Income: ${updatedIncome.name}, From: ${updatedIncome.from}, Till: ${updatedIncome.till}`);


        // Check if the user is logged in
        const user = auth.currentUser;
        if (user) {
          try {
            console.log("Saving updated income to Firestore", this.incomeList);
            await this.saveIncomeToFirestore(user.uid); // Autosave the updated income list
          } catch (error) {
            console.error("Error autosaving income: ", error);
          }
        }
      }
    },
    async saveIncomeToFirestore(userId) {
      try {
        const incomeData = { incomeList: this.incomeList };
        await updateUserInFirestore(userId, incomeData); // Update the Firestore document with the current incomeList
        console.log("Income data autosaved successfully");
      } catch (error) {
        console.error("Error saving income data to Firestore: ", error);
      }
    },
    async fetchIncomeFromFirestore(userId) {
      try {
        const userData = await getUserFromFirestore(userId);
        if (userData && userData.incomeList) {
          this.incomeList = userData.incomeList;
          console.log("Income data fetched successfully");
        }
      } catch (error) {
        console.error("Error fetching income data from Firestore: ", error);
      }
    },

    updateIncomeAges() {
      const globalStore = useGlobalStore();

      this.incomeList.forEach((income) => {
        // Update 'from' if it matches either the old currentAge or old retirementAge
        if (income.from === globalStore.oldCurrentAge) {
          income.from = globalStore.currentAge;  // Update to the new currentAge
        } else if (income.from === globalStore.oldRetirementAge) {
          income.from = globalStore.retirementAge;  // Update to the new retirementAge
        }

        // Update 'till' if it matches old currentAge, old retirementAge, or oldLivetillAge
        if (income.till === globalStore.oldCurrentAge) {
          income.till = globalStore.currentAge;  // Update to the new currentAge
        } else if (income.till === globalStore.oldRetirementAge) {
          income.till = globalStore.retirementAge;  // Update to the new retirementAge
        } else if (income.till === globalStore.oldLivetillAge) {
          income.till = globalStore.livetillAge;  // Update to the new livetillAge
        }

        // Update 'inflation rate'
        if (income.changetype === "Match Inflation") {
          income.change = globalStore.inflationRate;  // Update to the new currentAge
        }

      });

      console.log("Updated incomeList with new from/till values:", this.incomeList);
  
      // Optionally, save the updated income to Firestore if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          this.saveIncomeToFirestore(user.uid);
        } catch (error) {
          console.error("Error autosaving updated income ages: ", error);
        }
      }
    }
  },
});

