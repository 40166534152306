<template>
  <router-link :to="url" class="nav-link" v-bind="$attrs">
    <div
      class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    >
  </router-link>
</template>
<script>
export default {
  name: "sidenav-item",
  props: {
    url: {
      type: String,
      required: true
    },
    navText: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.nav-link {
    display: block;
    padding: 0.5rem 1rem; /* Consistent spacing */
    max-width: 100%; /* Prevent overflow */
    text-overflow: ellipsis; /* Add "..." for long text */
    overflow: hidden;
    white-space: nowrap; /* Prevent wrapping */
    color: #344767; /* Match design color */
    transition: color 0.15s ease-in-out;
}
</style>
