<template>
  <div class="dropdown">
    <button
      class="btn btn-bg-white dropdown-toggle"
      type="button"
      :id="dropdownId"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="width: 100%; text-align: left;"
    >
      <i :class="[getGenderIcon, selectedOptionColorClass]"></i> <span class="icon-text-spacing">{{ selectedOption || placeholder }}</span>
      
    </button>
    <ul class="dropdown-menu fixed-width" aria-labelledby="dropdownMenuButton">
      <li v-for="option in options" :key="option">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="selectOption(option)"
        >
          <i :class="[getOptionIconClass(option).icon, getOptionIconClass(option).color]"></i> <span class="icon-text-spacing">{{ option }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { useGlobalStore } from '../store/globalStore'; // Adjust the import path as needed
export default {
  props: {
    placeholder: {
      type: String,
      default: "Select an option",
    },
    dropdownId: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String, // Assuming the default value is a string
      default: null, // No default value initially
    },
  },
  data() {
    return {
      options: ["Me", "Spouse", "Joint"], // Define options internally
      selectedOption: this.defaultValue, // Initialize selectedOption with defaultValue
    };
  },
  computed: {
    getGenderIcon() {
      const store = useGlobalStore();
      // Determine which icon to return based on the selectedOption
      switch (this.selectedOption) {
        case "Me":
          return store.getGenderIcon; // Assuming this returns the correct icon for "Me"
        case "Spouse":
          return store.getSpouseGenderIcon; // Assuming this returns the correct icon for "Spouse"
        case "Joint":
          return 'fa fa-home'; // Example icon class for "Joint"
        default:
          return ''; // Default icon or logic when no option is selected
      }
    },
    selectedOptionColorClass() {
      // Logic to determine color based on the selected option
      if (this.selectedOption === "Me") {
        return 'me-color';
      } else if (this.selectedOption === "Spouse") {
        return 'spouse-color';
      } else {
        // Default color or another logic for "Joint" or when no option is selected
        return 'joint-color'; // Make sure to define this class in your styles
      }
    }
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.$emit("selected", option);
    },
    getOptionIconClass(option) {
      const store = useGlobalStore();
      let iconClass = '';
      let colorClass = '';
      if (option === "Me") {
        iconClass = store.getGenderIcon;
        colorClass = 'me-color'; // Assign primary color to "Me"
      } else if (option === "Spouse") {
        iconClass = store.getSpouseGenderIcon;
        colorClass = 'spouse-color'; // Assign secondary color to "Spouse"
      } else {
        // Handle joint icon differently if needed
        iconClass = 'fa fa-home';
        colorClass = 'joint-color'; // Choose a color for "Joint"
      }

      return { icon: iconClass, color: colorClass };
    },
  },
};
</script>

<style scoped>
/* Add custom CSS to make the dropdown full width */
.fixed-width {
  width: 100% !important; /* Adjust the width as needed */
}
.icon-text-spacing {
  margin-left: 10px; /* Adjust the margin as needed to create space between icon and text */
}

.me-color {
  color: #007bff; /* Replace with your primary color */
}

.spouse-color {
  color: #b324a0; /* Replace with your secondary color */
}

.joint-color {
  color: #34b25a; /* Replace with your secondary color */
}

</style>