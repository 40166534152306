<template>
  
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                </div>
                <div class="card-body">
                  <div class="row justify-content-center"> <!-- Google logo-->
                      <div class="col-3 d-flex justify-content-center align-items-center text-center">
                        <a class="btn btn-outline-light" @click="googleSignIn" href="javascript:;">
                          <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g transform="translate(3.000000, 2.000000)" fill-rule="nonzero">
                                <path
                                  d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                                  fill="#34A853"
                                />
                                <path
                                  d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                                  fill="#EB4335"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                  <div class="mt-2 position-relative text-center"> <!-- line for 'or' -->
                      <p
                        class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                      >or</p>
                    </div>
                  <form role="form" @submit.prevent="login">
                    <p class="mb-0">Enter your email and password to sign in</p>
                    <div class="mb-3 mt-3">
                      <argon-input v-model:value="email" type="email" placeholder="Email" name="email" size="lg" />
                    </div>
                    <div class="mb-3">
                      <argon-input v-model:value="password" type="password" placeholder="Password" name="password" size="lg" />
                    </div>
                    <argon-switch id="rememberMe">Remember me</argon-switch>

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        type="submit"
                      >Sign in</argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                      @click="gotoSignup"
                    >Sign up</a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-start overflow-hidden"
                :style="{ backgroundImage: `url(${bckgImage})`, backgroundSize: 'cover' }"          
              >
                
                <span class="mask bg-gradient-dark opacity-1"></span>
                <h5
                  class="mt-5 text-white font-weight-bolder position-relative"
                >" The difference between a dream and a goal - is a plan "</h5>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

import { loginUser, signInWithGoogle } from '../authService.js';
import { useUserStore } from '../store/useUserStore';

export default {
  name: "signin",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
  },
  data() {
    return {
      email: '',
      password: '',
      bckgImage: require('../assets/img/illustrations/car2.png') // Path to your local image
    };
  },
  methods: {
    async login() {
      try {
        if (!this.email || !this.password) {
          throw new Error('Email and password are required');
        }
        const userCredential = await loginUser(this.email, this.password);
        const user = userCredential.user;

        // Set the user in the Pinia store
        const userStore = useUserStore();
        userStore.setUser(user);

        console.log('User logged in successfully:', user);
        
        this.$router.push('/Finance')

      } catch (error) {
        console.error("Error login:", error.message);
        // Handle errors here (e.g., display an error message to the user)
      }
    },
    async googleSignIn() {
      try {
        const userCredential = await signInWithGoogle();
        const user = userCredential.user;

        // Set the user in the Pinia store
        const userStore = useUserStore();
        userStore.setUser(user);

        console.log('Google Sign-In successful:', user);

        this.$router.push('/Finance');
      } catch (error) {
        console.error("Error signing in with Google:", error.message);
        // Handle errors here (e.g., display an error message to the user)
      }
    },
    clearUser() {
      const userStore = useUserStore();
      userStore.clearUser();
    },
    gotoSignup() {
      this.$router.push('/signup');
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
