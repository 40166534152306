<!--change line 3,8,129,130,185-192-->
<template>
  <div class="modal" ref="ModalExpHousing" tabindex="-1" role="dialog"> <!-- ref="ModalFreelancing" is important as this is referred in openModal() & closeModal() as well as 'MyModal.vue'-->
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document"> <!-- modal-lg increase size on medium and large devices-->
      <div class="modal-content px-md-4"> <!-- p-md-4 is padding on medium and large devices only-->
        
        <div class="modal-header">
          <h4 class="modal-title">Housing</h4>
          <!-- Close button or icon can go here -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <div class="row">
            <div class="row">
              <div class="col-md-8"> <!--choose a name input field-->
                <label for="name">Name:</label>
                <argon-input v-model:value="inputValue" placeholder="Enter Name" id="name"/>
              </div>
              <div class="col-md-4 pl-4"> <!--earner is me/spouse dropdown-->
                <div class="form-group"> 
                  <label for="Earner">Spender:</label>
                  <div>
                    <earner-dropdown :dropdown-id="'Earner'" @selected="handleSelectedEarnerOption" :defaultValue="earnerSelectedOption"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8"> <!--amount input-->
                <label for="amount">Amount:</label>
                <argon-input v-model:value="amountValue" placeholder="Enter Amount" id="amount" type="number"/>
                <h6 class="mb-0 text-sm text-uppercase font-weight-bold" style="padding-left: 12px">{{ indianString }}</h6>
                <p>{{ updateIndianString() }}</p>
              </div>
              <div class="col-md-4 pl-4"> <!--If on mortgage-->
                <label for="hdropdown">Choose type:</label>
                <FreeDropdown :dropdown-id="hdropdown" :options="housingDropdownOptions" :defaultValue="housingSelectedOption" @selected="handleHousingSelectedOption"/>
              </div>
            </div> 

            <div class="section section-border p-3 mt-4"> <!--Section 1-->
              <div class="row mb-1">
                <div class="col-12"> <!--Section 1 Heading-->
                  <div class="section-header" @click="toggleSection('section1')">
                    <h6 class="font-weight-bold">Time Period</h6>
                    <i class="section-icon" :class="{'fa fa-chevron-down': !isSection1Open, 'fa fa-chevron-up': isSection1Open}"></i>
                  </div>
                </div>
              </div>
              <!-- ... Section 1 content ... -->
              <div v-show="isSection1Open">
                <div class="row">
                  <div class="col-md-6 col-12"> <!--frequency monthly / annual dropdown-->
                    <div class="form-group">
                      <label for="frequency">Frequency:</label>
                      <frequency-dropdown :dropdown-id="'frequency'" @selected="handleFrequencySelectedOption" :defaultValue="frequencySelectedOption"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12"> <!--Current age dropdown-->
                    <div class="form-group">
                      <label for="from">From:</label>
                      <age-dropdown :dropdown-id="'from'" :options="concatOptions()" @selected="handleFromAgeSelectedOption" :defaultValue="fromSelectedOption"/>
                    </div>
                  </div>
                  <div class="col-md-6 col-12"> <!--till age dropdown-->
                    <div class="form-group"> 
                      <label for="till">Till:</label>
                      <age-dropdown :dropdown-id="'till'" :options="concatOptions()" @selected="handleTillAgeSelectedOption" :defaultValue="tillSelectedOption"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="section section-border p-3" v-if="showSection2"> <!--Section 2-->
              <div class="row mb-1">
                <div class="col-12"> <!--Section 2 heading-->
                  <div class="section-header" @click="toggleSection('section2')">
                    <h6 class="font-weight-bold">Change Over Time</h6>
                    <i class="section-icon" :class="{'fa fa-chevron-down': !isSection2Open, 'fa fa-chevron-up': isSection2Open}"></i>
                  </div>
                </div>
              </div>
              <!-- ... Section 2 content ... -->
              <div v-show="isSection2Open">
                <div class="row">
                  <div class="col-md-6 col-12"> <!--rate change dropdown-->
                    <div class="form-group"> 
                      <label for="changeDropdown">Change:</label>
                      <change-dropdown :dropdown-id="'changeDropdown'" @selected="handleSelectedChangeOption" :defaultValue="selectedChangeOption"/>
                    </div>
                  </div>
                  <div class="col-md-4 col-12"> <!--rate change value-->
                    <label for="saLChangePerc">Annual Percentage Change:</label>
                    <argon-input type="number" id="saLChangePerc" v-model:value="salChangePercValue" aria-label="change in salary in percentage" icon="fa fa-percent" iconDir="right" />                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <a @click="closeModal" style="cursor: pointer; margin-right: 30px;">Back</a>
          <argon-button @click="saveExpense" color="primary" size="md" variant="gradient">Add</argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from './ArgonInput.vue';
import ArgonButton from './ArgonButton.vue';
import {useExpenseStore} from '../store/expense';
import {useGlobalStore} from '../store/globalStore';
import EarnerDropdown from './EarnerDropdown.vue';
import FrequencyDropdown from './FrequencyDropdown.vue';
import AgeDropdown from './AgeDropdown.vue';
import ChangeDropdown from './ChangeDropdown.vue';
import FreeDropdown from './FreeDropdown.vue';

export default {
  data() {
    return {
      expense: {}, // Store the incoming income object here
      iconCode: 'fa fa-home',
      inputValue: 'Housing', // Initialize the input value
      amountValue: 20000, //Default value
      selectedChangeOption: 'Match Inflation', // Default selected change option
      isSection1Open: false,
      isSection2Open: false,
      showSection2: true, // Initially, show section 2
      earnerSelectedOption: 'Me',
      housingSelectedOption: 'Rented',
      frequencySelectedOption: 'Monthly',
      fromSelectedOption: 'Current Age',
      tillSelectedOption: 'Life Expectancy',
      salChangePercValue: 0, // arbitrary value, getting changed at initiation through 'created' hook
      indianString: '',
      housingDropdownOptions: ["Rented", "Owned - On Mortgage", "Owned"], // Define options internally
      type: "housing"
    };
  },
  components: {
    ArgonInput,
    ArgonButton,
    EarnerDropdown,
    FrequencyDropdown,
    AgeDropdown,
    ChangeDropdown,
    FreeDropdown
  },
  computed: {
    currentAge() { // Access currentAge from globalStore
      return useGlobalStore().currentAge; 
    },
    retirementAge() { // Access retirementAge from globalStore
      return useGlobalStore().retirementAge; 
    },
    changePercentage() { // for showing %age in input field , basis the value of Change dropdown
      // Calculate and return the appropriate change percentage based on the selectedChangeOption
      const globalStore = useGlobalStore(); // for changing the inflation rate to the one stored in global store (selected at setup stage)
      switch (this.selectedChangeOption) {
        case 'Increase':
          return 10.00;
        case 'Decrease':
          return 2.00;
        case 'Match Inflation':
          return globalStore.inflationRate;
        default:
          return 12.00; // Default to Increase if the option is not recognized
      }
    },
  },
  created() {
    // Initialize salChangePercValue during component creation so that it is as per the default 'Increase' dropdown option leading to value as 10
    this.salChangePercValue = this.changePercentage;
    this.updateIndianString();  
  },
  watch: {
    selectedChangeOption() {
      // Update salChangePercValue when selectedChangeOption dropdown changes
      this.salChangePercValue = this.changePercentage;
    },
  },
  methods: {
    openModal(expense = null) {
      if (expense) {
        this.expense = { ...expense }; // Clone the income object to avoid mutating the original
        this.inputValue = expense.name;
        this.amountValue = expense.amount;
        this.earnerSelectedOption = expense.earner;
        this.frequencySelectedOption = expense.frequency;
        this.fromSelectedOption = expense.from;
        this.tillSelectedOption = expense.till;
        this.selectedChangeOption = expense.changetype;
        this.salChangePercValue = expense.change;
        this.housingSelectedOption = expense.housingSelectedOption;
      } else {
        this.resetForm();
      }

      console.log("Modal opened with expense: ", this.expense)

      this.$refs.ModalExpHousing.classList.add('show');
      this.$refs.ModalExpHousing.style.display = 'block';
      document.body.classList.add('modal-open-body');
    },
    resetForm() {
      const globalStore = useGlobalStore(); // for changing the inflation rate to the one stored in global store (selected at setup stage)
      this.iconCode = 'fa fa-home';
      this.inputValue = 'Housing';
      this.amountValue = 20000;
      this.selectedChangeOption = 'Match Inflation';
      this.earnerSelectedOption = 'Me';
      this.frequencySelectedOption = 'Monthly';
      this.fromSelectedOption = this.currentAge;
      this.tillSelectedOption = globalStore.livetillAge;
      this.salChangePercValue = globalStore.inflationRate;
      
      this.housingSelectedOption = 'Rented',

      console.log("Form reset to default value")
    },
    saveExpense() {
      const expenseStore = useExpenseStore();

      if (!this.expense.id) {
        // New income entry
        const newExpense = {
          id: generateUniqueId(),
          name: this.inputValue,
          amount: this.amountValue,
          earner: this.earnerSelectedOption,
          frequency: this.frequencySelectedOption,
          from: this.fromSelectedOption,
          till: this.tillSelectedOption,
          changetype: this.selectedChangeOption,
          change: this.salChangePercValue,
          icon: this.iconCode,
          type: this.type,
          
          ownershiptype: this.housingSelectedOption,
          category: 'Housing',
        };
        console.log("Adding new expense", newExpense)
        expenseStore.addExpense(newExpense);
      } else {
        // Existing income update
        this.expense.name = this.inputValue;
        this.expense.amount = this.amountValue;
        this.expense.earner = this.earnerSelectedOption;
        this.expense.frequency = this.frequencySelectedOption;
        this.expense.from = this.fromSelectedOption;
        this.expense.till = this.tillSelectedOption;
        this.expense.changetype = this.selectedChangeOption;
        this.expense.change = this.salChangePercValue;
        this.expense.type = this.type;

        this.expense.ownershiptype = this.housingSelectedOption;
        this.expense.category = 'Housing';

        console.log("Updating existing expense:", this.expense)

        this.$emit('save-expense', this.expense); // Notify parent component of updated income
      }

      this.closeModal(); // Close the modal after saving
    },
    closeModal() {// for closing this Modal - ModalSalary
      this.$refs.ModalExpHousing.classList.remove('show');
      this.$refs.ModalExpHousing.style.display = 'none';
      document.body.classList.remove('modal-open-body');
    },
    // Method to update the Indian string representation based on the input value
    updateIndianString() {
      const rawValue = this.amountValue;
      const numberValue = parseInt(rawValue, 10);
      this.indianString = numberToWordsIndian(numberValue);
    },
    handleSelectedOption(option) { // to assign value of all dropdowns , except change dropdown
      this.selectedOption = option;
    },
    handleSelectedEarnerOption(option) { // to assign value of all dropdowns , except change dropdown
      this.earnerSelectedOption = option;
    },
    handleHousingSelectedOption(option) { // to assign value of all dropdowns , except change dropdown
      this.housingSelectedOption = option;
      // Check if the selected option is 'Owned - On Mortgage'
      if (option === 'Owned - On Mortgage') {
        // Hide Section 2 if the selected option is 'Owned - On Mortgage'
        this.showSection2 = false;
        this.salChangePercValue = 0;
      } else {
        // Show Section 2 for other selected options
        this.showSection2 = true;
      }
    },
    handleFrequencySelectedOption(option) { // to assign value of all dropdowns , except change dropdown
      this.frequencySelectedOption = option;
    },
    handleFromAgeSelectedOption(option) { // to assign value of all dropdowns , except change dropdown
      this.fromSelectedOption = option;
    },
    handleTillAgeSelectedOption(option) { // to assign value of all dropdowns , except change dropdown
      this.tillSelectedOption = option;
    },
    handleSelectedChangeOption(option) { // to assign value of change dropdown
      this.selectedChangeOption = option;
    },
    concatOptions() { // to show options in dropdown field
      // Combine startingDropdownOptions and ageAndYearArrayStartingFromNextYear
      const globalStore = useGlobalStore();
      const startingDropdownOptions = [
        { label: 'Current Age', value: globalStore.currentAge },
        { label: 'Retirement Age', value: globalStore.retirementAge },
        { label: 'Life Expectancy', value: globalStore.livetillAge },
      ];
      // Transform ageAndYearArrayStartingFromNextYear into the expected format
      const ageAndYearArrayOptions = globalStore.ageAndYearArrayStartingFromNextYear.map(entry => ({
        label: `${entry.period} Year, ${entry.year} , Age ${entry.age} `,
        value: entry.age, // Use the age as the value
        //value: entry.year, // Use the year as the value
      }));
      return startingDropdownOptions.concat(ageAndYearArrayOptions);
    },
    toggleSection(section) { // to show as collapsible section
      if (section === 'section1') {
        this.isSection1Open = !this.isSection1Open;
        this.isSection2Open = false; // Close the other section
      } else if (section === 'section2') {
        this.isSection2Open = !this.isSection2Open;
        this.isSection1Open = false; // Close the other section
      }
    },
    
  },
};
// Function to generate unique IDs
function generateUniqueId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}
// Function to convert number to Indian words
function numberToWordsIndian(number) {
  const belowTwenty = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  
  if (number < 20) { 
    return belowTwenty[number];
  }
  if (number < 100) {
    const result = tens[Math.floor(number / 10)] + (number % 10 !== 0 ? " " + belowTwenty[number % 10] : "");
    return result;
  }
  if (number < 1000) {
    const result = belowTwenty[Math.floor(number / 100)] + " Hundred" + (number % 100 !== 0 ? " and " + numberToWordsIndian(number % 100) : "");
    return result;
  }
  if (number < 100000) {
    let thousands = Math.floor(number / 1000);
    let remainder = number % 1000;
    const result = numberToWordsIndian(thousands) + " Thousand" + (remainder !== 0 ? ", " + numberToWordsIndian(remainder) : "");
    return result;
  }
  if (number < 10000000) {
    let lakhs = Math.floor(number / 100000);
    let remainder = number % 100000;
    const result = numberToWordsIndian(lakhs) + " Lakh" + (remainder !== 0 ? ", " + numberToWordsIndian(remainder) : "");
    return result;
  }
  if (number < 1000000000) { 
    let crores = Math.floor(number / 10000000);
    let remainder = number % 10000000;
    const result = numberToWordsIndian(crores) + " Crore" + (remainder !== 0 ? ", " + numberToWordsIndian(remainder) : "");
    return result;
  }

  return "";

}
</script>


<style>
/* CSS to disable scrolling on the body when the modal is open */
.modal-open-body {
  overflow: hidden;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.section-icon {
  margin-left: auto;
}

.form-group {
  margin-bottom: 8px !important;
}

/* Add a custom CSS class for right alignment */
.custom-right-align {
  text-align: right !important;
}

.section-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 5px; /* Adjust the border radius as needed for curved corners */
  
}


</style>