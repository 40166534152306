import { defineStore } from 'pinia';
import {useGlobalStore} from '../store/globalStore';
import { updateUserInFirestore, getUserFromFirestore } from '../dbService';
import { auth } from '../main'; // Import Firebase auth to check login status

export const useSavingStore = defineStore('saving', {
  state: () => { // defined savingList array to store data
    const globe = useGlobalStore();
    return {
      savingList: [
        {
          id: 5,
          name: "Fixed Deposit",
          amount: 10000,
          dropdown1: "No",
          newAmount: 0,
          frequency: "Annual",
          from: globe.currentAge,
          till: globe.retirementAge,
          annualIncrement: 0,
          change: 7,
          icon: "fa fa-archive",
          earner: "Me",
          type: "fd"
        },
        {
          id: 6,
          name: "Mutual Fund",
          amount: 20000,
          dropdown1: "Yes",
          newAmount: 5000,
          frequency: "Monthly",
          from: globe.currentAge,
          till: globe.retirementAge,
          annualIncrement: 5,
          change: 10,
          icon: "fa fa-cubes",
          earner: "Joint",
          type: "mf"
        },
      ]
    }
  },

  actions: {
    async addSaving(saving) { // to add incoming data in savingList array. this function is called from ModalFD etc.
      this.savingList.push(saving);

      // Check if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          await this.saveSavingToFirestore(user.uid); // Autosave the updated saving list by calling function, mentioned below
        } catch (error) {
          console.error("Error autosaving saving: ", error);
        }
      }
    },
    async updateSaving(updatedSaving) { // to update saving
      const index = this.savingList.findIndex(saving => saving.id === updatedSaving.id);
      if (index !== -1) {
        this.savingList[index] = updatedSaving;

        // Check if the user is logged in
        const user = auth.currentUser;
        if (user) {
          try {
            console.log("Saving updated saving to Firestore", this.savingList);
            await this.saveSavingToFirestore(user.uid); // Autosave the updated saving list
          } catch (error) {
            console.error("Error autosaving saving: ", error);
          }
        }
      }
    },
    async saveSavingToFirestore(userId) {
      try {
        const savingData = { savingList: this.savingList };
        await updateUserInFirestore(userId, savingData); // Update the Firestore document with the current savingList
        console.log("Saving data autosaved successfully");
      } catch (error) {
        console.error("Error saving savings data to Firestore: ", error);
      }
    },
    async fetchSavingFromFirestore(userId) {
      try {
        const userData = await getUserFromFirestore(userId);
        if (userData && userData.savingList) {
          this.savingList = userData.savingList;
          console.log("Saving data fetched successfully");
        }
      } catch (error) {
        console.error("Error fetching saving data from Firestore: ", error);
      }
    },

    updateSavingAges() {
      const globalStore = useGlobalStore();

      this.savingList.forEach((saving) => {
        // Update 'from' if it matches either the old currentAge or old retirementAge
        if (saving.from === globalStore.oldCurrentAge) {
          saving.from = globalStore.currentAge;  // Update to the new currentAge
        } else if (saving.from === globalStore.oldRetirementAge) {
          saving.from = globalStore.retirementAge;  // Update to the new retirementAge
        }

        // Update 'till' if it matches old currentAge, old retirementAge, or oldLivetillAge
        if (saving.till === globalStore.oldCurrentAge) {
          saving.till = globalStore.currentAge;  // Update to the new currentAge
        } else if (saving.till === globalStore.oldRetirementAge) {
          saving.till = globalStore.retirementAge;  // Update to the new retirementAge
        } else if (saving.till === globalStore.oldLivetillAge) {
          saving.till = globalStore.livetillAge;  // Update to the new livetillAge
        }
      });

      console.log("Updated savingList with new from/till values:", this.savingList);
  
      // Optionally, save the updated saving to Firestore if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          this.saveSavingToFirestore(user.uid);
        } catch (error) {
          console.error("Error autosaving updated income ages: ", error);
        }
      }
    }
  },
});
