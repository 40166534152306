<template>
  <div class="homepage">
    <!-- Transparent Header -->
    <header class="position-fixed w-100 py-3 bg-transparent header-margin">
      <div class="container d-flex justify-content-between align-items-center">
        <img src="../assets/img/logos/Fintelligent.png" alt="Fintelligent Logo" class="logo">
        <nav>
          <ul class="d-flex list-unstyled mb-0 square-btn">
            <button class="btn btn-dark" @click="openLoginPage">Login</button>
          </ul>
        </nav>
      </div>
    </header>

    <!-- Section 1 with background image and divider -->
    <section class="d-flex align-items-center section-1">
      <div class="container">
        <div class="row">
          <!-- Left Side -->
          <div class="col-md-6 col-12  d-flex flex-column justify-content-center order-2 order-md-1">
            <h1 class="display-4 font-weight-bolder">Money matters made Simple</h1>
            <p class="lead text-dark">Fintelligent makes managing your finances effortless. Use advanced tools to understand and assess your financial status. Whether aiming for big milestones like buying a home or preparing for retirement, Fintelligent guides your goal-setting process. Stay on track toward your aspirations without sacrificing your lifestyle</p>
            <button class="btn btn-primary mt-3 custom-btn" @click="openSetupPage">Get Started</button>
          </div>

          <!-- Right Side with full-height image -->
          <div class="col-md-6 col-12 d-flex align-items-center justify-content-center order-1 order-md-2">
            <img src="../assets/img/illustrations/coin2.png" alt="Financial Planning" class="img-fluid full-height-image"/>
          </div>
        </div>
      </div>
    </section>

    
    <!-- Section 2 -->
    <section class="d-flex align-items-center bg-light">
      <div class="container">
        <div class="row">
          <!-- Left Side -->
          <div class="col-md-6 px-4 d-flex flex-column justify-content-center">
            <h1 class="display-4 font-weight-bold">Budget Digitally</h1>
            <h4 class="my-2">Take Control of Your Finances</h4>
            <p class="lead">Fintelligent helps you track your income, expenses, and savings with ease. Get a comprehensive view of your financial health and make informed decisions for a secure future.</p>
          </div>

          <!-- Right Side with image -->
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/illustrations/budget.png" alt="Financial Planning Goals" class="img-fluid full-height-image"/>
          </div>
        </div>
      </div>
    </section>



    <!-- Section 3 -->
    <section class="vh-100 d-flex align-items-center section-3">
      <div class="container">
        <div class="row">
          <!-- Left Side -->
          <div class="col-md-6 px-4 d-flex flex-column justify-content-center">
            <h1 class="display-4 font-weight-bold">Analyze</h1>
            <h4 class="my-2">Understand and Optimize Your Wealth</h4>
            <p class="lead">Leverage powerful digital tools to assess your financial well-being. Track the performance of your numbers and uncover insights to improve your financial strategy.  </p>
          </div>

          <!-- Right Side with image -->
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/illustrations/kidlego.png" alt="Financial Planning Goals" class="img-fluid full-height-image"/>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 4 -->
    <section class="vh-100 d-flex align-items-center bg-light">
      <div class="container">
        <div class="row">
          <!-- Left Side -->
          <div class="col-md-6 px-4 d-flex flex-column justify-content-center section-4">
            <h1 class="display-4 font-weight-bold">Set Goals</h1>
            <h4 class="my-2">Set, Track, and Achieve Your Financial Milestones</h4>
            <p class="lead">Whether you’re saving for a car, home, child's eduction or retirement, Fintelligent helps you plan and monitor your goals. AI insights (coming soon!) will help you see how achievable your goals are based on your unique financial profile. </p>
          </div>

          <!-- Right Side with image -->
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/illustrations/elderly.png" alt="Financial Planning Goals" class="img-fluid full-height-image"/>
          </div>
        </div>
      </div>
    </section>


    <!-- Section 5 -->
    <section class="vh-100 d-flex align-items-center">
      <div class="container">
        <div class="row">
          <!-- Left Side -->
          <div class="col-md-6 px-4 d-flex flex-column justify-content-center section-5">
            <h1 class="display-4 font-weight-bold">Achieve</h1>
            <h4 class="my-2">Assign Logic to your Emotions</h4>
            <p class="lead">Fintelligent empowers you to achieve your dreams without compromising your lifestyle. Balance your spending with your goals and make every decision count.</p>
            <p class="lead">Be financially intelligent. Be Fintelligent.</p>
          </div>

          <!-- Right Side with image -->
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/illustrations/car.png" alt="Financial Planning Goals" class="img-fluid full-height-image"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>



<script>

export default {
  name: "HomeComponent",
  methods: {
    openSetupPage() {
      this.$router.push('/Setup')
    },
    openLoginPage() {
      this.$router.push('/signin')
    },
  },
};
</script>

<style scoped>

.homepage {
  background-color: white;
}

.logo {
  width: 200px; /* Adjust logo size */
}

/* Add margins to the header */
.header-margin {
  margin-top: 10px;
  margin-right: 10px;
}

.section-1 {
  padding-top: 50px;
  min-height: 100vh; /* Default full-height behavior */
  overflow: hidden; /* Prevent content overflow */
}


/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .section-1{
    height: auto; /* Let sections grow with content */
    margin-bottom: 2rem; /* Add space between sections */
  }
}


/* Image should take 100% height and not stretch proportionally */
.full-height-image {
  max-height: 70vh; /* Make sure the image fits within the viewport height */
  width: auto; /* Ensure image height takes priority */
  object-fit: cover; /* Ensure the image fits nicely without distortion */
  border-radius: 40px;
}

/* Button size - make it smaller and compact */
.custom-btn {
  width: 200px;
  padding: 10px 20px; /* Adjust the padding to make the button more compact */
  font-size: 16px;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .custom-btn {
    width: 100%;
  }
}

.btn-success {
  border-radius: 0;
}

.divider {
  border: 0;
  height: 2px;
  background: #ddd;
}

/* Header styles */
header {
  z-index: 1000;
  margin-bottom: 100px; /* Adjust according to the height of your header */

  @media (max-width: 768px) {
    margin-bottom: 3rem; /* Adjust for mobile screens */
  }
}

/* Adjustments for full height sections */
section {
  min-height: 100vh;
}

h1 {
  color: #000000;
}

h4 {
  color: #2c3e50;
}

p.lead {
  font-size: 1 rem;
}

.bg-light {
  background-color: #f9f9f9;
}

.section-1 h1 {
  margin-bottom: 1.5rem; /* Adjusts spacing below the heading */
}

.section-1 p {
  margin-bottom: 3rem; /* Adjusts spacing below the paragraph */
}

.section-1 .custom-btn {
  margin-top: 2rem; /* Ensures proper separation from the paragraph */
}
</style>