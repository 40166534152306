<template>
  <div class="dropdown">
    <button
      class="btn btn-white dropdown-toggle"
      type="button"
      :id="dropdownId"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="width: 100%; text-align: left;"
    >
      {{ selectedOption || placeholder }}
    </button>
    <!--first line below is to stop showing the entire dropdown at once, but only some items-->
    <ul
      :class="{ 'scrollable-dropdown': options.length > maxVisibleItems }"
      class="dropdown-menu fixed-width"
      aria-labelledby="dropdownMenuButton"
    >
      <li v-for="option in options" :key="option.value">
        <a class="dropdown-item" href="#" @click="selectOption(option)">
          {{ option.label }} - {{ option.value }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { useGlobalStore } from "../store/globalStore";

export default {
  props: {
    placeholder: {
      type: String,
      default: "Select an option",
    },
    defaultValue: {
      type: String,
      default: null, // No default value initially
    },
    dropdownId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null, // Initialize selectedOption with defaultValue
      maxVisibleItems: 5, // Adjust the maximum visible items as needed
    };
  },
  computed: {
    options() {
      // Use concatOptions method to provide options for the dropdown
      return this.concatOptions();
    },
    currentValue() {
      // Access the value from the global store based on the defaultValue string
      return useGlobalStore()[this.defaultValue];
    },
  },
  created() {
    // Find the option object with the label "Current Age" in the options array
    const defaultOption = this.options.find(
      (option) => option.label === this.defaultValue
    );
    if (defaultOption) {
      // Set the selectedOption to the label of the found option
      this.selectedOption = defaultOption.label;
      // Emit the value of the found option
      this.$emit("selected", defaultOption.value);
    }
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option.label; // Set selectedOption to the label
      this.$emit("selected", option.value); // Emit only the value
    },
    concatOptions() {
      // Combine startingDropdownOptions and ageAndYearArrayStartingFromNextYear
      const globalStore = useGlobalStore();
      const startingDropdownOptions = [
        { label: "Current Age", value: globalStore.currentAge },
        { label: "Retirement Age", value: globalStore.retirementAge },
        { label: "Life Expectancy", value: globalStore.livetillAge },
      ];
      // Transform ageAndYearArrayStartingFromNextYear into the expected format
      const ageAndYearArrayOptions = globalStore.ageAndYearArrayStartingFromNextYear.map(
        (entry) => ({
          label: `Year ${entry.year} , Age ${entry.age} `, // Customize the label as needed
          value: entry.age, // Use the age as the value
          //value: entry.year, // Use the year as the value
        })
      );
      return startingDropdownOptions.concat(ageAndYearArrayOptions);
    },
  },
};
</script>

<style scoped>
/* Add custom CSS to make the dropdown full width */
.fixed-width {
  width: 100% !important; /* Adjust the width as needed */
}

/* CSS to style the dropdown menu */
.scrollable-dropdown {
  max-height: 200px; /* Adjust the maximum height as needed */
  overflow-y: auto;
}
</style>
