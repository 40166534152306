import { auth, googleProvider} from './main';  // Import the initialized `auth` from main.js
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useIncomeStore } from './store/income';
import { useExpenseStore } from "./store/expense";
import { useSavingStore } from "./store/savings";
import {useGlobalStore} from './store/globalStore';

// Register user with email and pwd
export const registerUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
    return userCredential;
  } catch (error) {
    console.error("Error registering:", error.message);
    throw error;
  }
};

// Login user with email and pwd
export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    

    // Fetch income data from Firestore
    const incomeStore = useIncomeStore();
    const expenseStore = useExpenseStore();
    const savingStore = useSavingStore();
    const globalStore = useGlobalStore(); 
    await incomeStore.fetchIncomeFromFirestore(userCredential.user.uid);
    await expenseStore.fetchExpenseFromFirestore(userCredential.user.uid);
    await savingStore.fetchSavingFromFirestore(userCredential.user.uid);
    await globalStore.fetchGlobalDataFromFirestore(userCredential.user.uid);

    return userCredential;
  } catch (error) {
    console.error("Error logging in:", error.message);
    throw error;
  }
};

// Login or Register with Google
export const signInWithGoogle = async () => {
    try {
        const userCredential = await signInWithPopup(auth, googleProvider);
        

        // Fetch income data from Firestore after Google Sign-in
        const incomeStore = useIncomeStore();
        const expenseStore = useExpenseStore();
        const savingStore = useSavingStore();
        const globalStore = useGlobalStore(); 
        await incomeStore.fetchIncomeFromFirestore(userCredential.user.uid);
        await expenseStore.fetchExpenseFromFirestore(userCredential.user.uid);
        await savingStore.fetchSavingFromFirestore(userCredential.user.uid);
        await globalStore.fetchGlobalDataFromFirestore(userCredential.user.uid);
        
        return userCredential;
    } catch (error) {
        console.error("Error signing in with Google:", error.message);
        throw error;
    }
};

// Sign out the user
export const logoutUser = async () => {
    try {
      await signOut(auth);
      
    } catch (error) {
      console.error("Error signing out:", error.message);
      throw error;
    }
};


