<template>
    <div class="dropdown">
      <button
        class="btn btn-bg-white dropdown-toggle"
        type="button"
        :id="dropdownId"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="width: 100%; text-align: left;"
      >
        {{ selectedOption || placeholder }}
        
      </button>
      <ul class="dropdown-menu fixed-width" aria-labelledby="dropdownMenuButton">
        <li v-for="option in options" :key="option">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectOption(option)"
          >
            {{ option }}
          </a>
        </li>
      </ul>
    </div>
</template>
  
<script>
export default {
    props: {
      placeholder: {
        type: String,
        default: "Select an option",
      },
      dropdownId: {
        type: String,
        required: true,
      },
      defaultValue: {
        type: String, // Assuming the default value is a string
        default: null, // No default value initially
      },
      options: {
        type: Array, // Change the type to Array to accept an array of options
        required: true, // Make the options prop required
      },
    },
    data() {
      return {
        selectedOption: this.defaultValue, // Initialize selectedOption with defaultValue
      };
    },
    methods: {
      selectOption(option) {
        this.selectedOption = option;
        this.$emit("selected", option);
      },
    },
};
</script>
  
<style scoped>
/* Add custom CSS to make the dropdown full width */
.fixed-width {
width: 100% !important; /* Adjust the width as needed */
}
.icon-text-spacing {
  margin-left: 10px; /* Adjust the margin as needed to create space between icon and text */
}
</style>
  