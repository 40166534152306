import { createApp } from "vue";
import { createPinia } from 'pinia';
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { useUserStore } from "./store/useUserStore";

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBg6Erk6GWTKgqdHW13ntGaJOHcJXNWyrQ",
    authDomain: "fintelligent-b374b.firebaseapp.com",
    projectId: "fintelligent-b374b",
    storageBucket: "fintelligent-b374b.appspot.com",
    messagingSenderId: "803977610107",
    appId: "1:803977610107:web:b1d0973ef72810177533de",
    measurementId: "G-FZJ5H1LDDY"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(firebaseApp); // Firebase Authentication
const db = getFirestore(firebaseApp); // Firestore Database
const googleProvider = new GoogleAuthProvider();
// const analytics = getAnalytics(app);

// Create Vue app instance
const appInstance = createApp(App);
const pinia = createPinia();

// Use Pinia, store, router, and other plugins
appInstance.use(pinia);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);

// Mount the Vue app
appInstance.mount("#app");

// This should ideally be placed in your main.js or a global store.
onAuthStateChanged(auth, (user) => {
    const userStore = useUserStore(); // Initialize the user store

    if (!user) {
        
        // Optionally reset any store values here or redirect the user to the login page
        userStore.clearUser(); // Clear the user from the store
    } else {
        
        // Fetch and handle logged-in user data, if needed
        userStore.setUser(user); // Set the user in the store
    }
});

// Export Firebase services if needed elsewhere
export { auth, db, googleProvider };