<template>

    <div>
      <!-- Add your chart rendering code here -->
      <!-- For example, if you're using Chart.js -->
      <canvas ref="chartCanvas" style="padding: 8px; min-height: 350px;"></canvas>
    </div>
    

</template>
  
<script>
    import Chart from "chart.js/auto";
    export default {
        props: {
            data: {
                type: Array,
                required: true,
            },
            labels: {
                type: Array,
                required: true,
            },
            borderColors: {
                type: Array,
                default: () => ['#5e72e4', '#FF8C00', '#2dce89','#11cdef','#8392ab','#f5365c' ] // Default border colors
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
            // Initialize and render your chart here
            this.renderChart();
        },
        watch: {
            data: {
                handler() {
                    // When the data prop changes, re-render the chart
                    this.renderChart();
                },
                deep: true, // Watch for changes within the array
            },
        },
        methods: {
            handleResize() {
                this.chartInstance.options.scales.y.display = window.innerWidth > 768;
                this.chartInstance.options.plugins.legend.display = window.innerWidth > 768;
                this.chartInstance.update();
            },
            renderChart() {
                // Use your chart library to create and render the chart
                // Example with Chart.js
                const ctx = this.$refs.chartCanvas.getContext("2d");

                // Check if a chart instance already exists, and destroy it
                if (this.chartInstance) {
                    this.chartInstance.destroy();
                }

                const gradientStroke = ctx.createLinearGradient(0, 600, 0, 100);
                gradientStroke.addColorStop(1, "rgba(94, 114, 228, 0.2)");
                gradientStroke.addColorStop(0.2, "rgba(94, 114, 228, 0.1)");
                gradientStroke.addColorStop(0, "rgba(94, 114, 228, 0)");

                this.chartInstance = new Chart(ctx, {
                    type: "line",
                    data: {
                        labels: this.labels,
                        datasets: this.data
                            .filter(dataset => dataset.name !== 'Left to allocate') // Exclude the "Left to allocate" dataset from being rendered
                            .map((dataset, index) => ({
                                label: dataset.name,
                                data: dataset.data,
                                fill: true,
                                backgroundColor: gradientStroke,
                                borderColor: this.borderColors[index % this.borderColors.length],
                                borderWidth: 2,
                                pointRadius: 2,
                                tension: 0.5,
                            })),
                    },
                    options: {
                        locale: 'en-IN',
                        responsive: true,
                        maintainAspectRatio: false, // Ensure the chart is responsive
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Age",
                                },
                            },
                            y: {
                                display: window.innerWidth > 768, // Only display y-axis labels on non-mobile views
                                title: {
                                    display: false,
                                    text: "Income",
                                },
                                
                            },
                        },
                        plugins: {
                            legend: {
                                display: window.innerWidth > 768, // Adjust this to fit your mobile breakpoint
                                position: 'top', // You can change the position to 'bottom' if it fits your design better
                            },
                            tooltip: {
                                enabled: true, // Enables tooltips
                                mode: 'index',
                                position: 'nearest', // Tooltip will appear close to the touch point
                                intersect: false, // Tooltips are shown for all items on the same index
                                callbacks: {
                                    // Custom tooltip callback
                                    label: (tooltipItem) => {
                                        const datasetLabel = tooltipItem.dataset.label || '';
                                        const value = tooltipItem.raw || 0;

                                        // Default tooltip for visible datasets
                                        let tooltipLabel = `${datasetLabel}: ₹${value.toLocaleString('en-IN')}`;

                                        return tooltipLabel;
                                    },
                                    afterBody: (tooltipItems) => {
                                        // Custom "Left to allocate" tooltip value, only shown once per hover
                                        const index = tooltipItems[0].dataIndex; // Get the hovered index
                                        const leftToAllocate = this.data[3].data[index]; // Access "Left to allocate" data
                                        
                                        // Only show "Left to allocate" if it is not null (i.e., before retirement year)
                                        if (leftToAllocate !== null && leftToAllocate !== undefined) {
                                            return `Left to allocate: ₹${leftToAllocate.toLocaleString('en-IN')}`;
                                        } else {
                                            return ''; // If not available, return an empty string
                                        }
                                    }
                                }
                            },
                        },
                        interaction: {
                            mode: 'nearest',
                            axis: 'x',
                            intersect: false
                        },
                    },
                });

            },
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
            // Ensure the chart instance is destroyed when the component is unmounted
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }
        },
    };
</script>
  
<style scoped>
/* Add any custom styling for your chart component here */
</style>