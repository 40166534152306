<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="
      this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''
    "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          
        </div>
        <ul class="navbar-nav justify-content-end">
          <!--
          <li class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Signin' }"
              class="px-0 nav-link font-weight-bold text-white"
              target="_blank"
            >
              <i
                class="fa fa-user"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-2'"
              ></i>
              <span v-if="this.$store.state.isRTL" class="d-sm-inline d-none"
                >يسجل دخول</span
              >
              <span v-else class="d-sm-inline d-none">Sign In</span>
            </router-link>
          </li>
          -->
          <li class="nav-item d-xl-none ps-3 mx-4 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <!-- Remove toggle configurator later
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="toggleConfigurator">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          -->
          
          <!-- User Info Section -->
            <li v-if="user" class="nav-item dropdown d-flex align-items-center">
              <a
                href="#"
                class="p-0 nav-link text-white"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                
                <span v-if="user?.photoURL">
                  <img :src="user.photoURL" class="avatar avatar-sm rounded-circle" alt="User Avatar" />
                </span>
                <span v-else>
                  <i class="fa fa-user fa-2x"></i> <!-- Display FontAwesome user icon when no photoURL is present -->
                </span>
                
              </a>
              <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <a class="dropdown-item" href="javascript:;">
                    Signed in as {{ user.email }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="logout" href="#">
                    Sign Out
                  </a>
                </li>
              </ul>
            </li>

            <!-- Sign In Button (only show if not logged in) -->
            <li v-else class="nav-item d-flex align-items-center">
              <router-link
                :to="{ name: 'Signin' }"
                class="px-0 nav-link font-weight-bold text-white"
              >
                <i class="fa fa-user me-sm-2"></i>
                <span class="d-sm-inline d-none">Sign In</span>
              </router-link>
            </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import { useUserStore } from "../../store/useUserStore";
import { logoutUser } from "../../authService";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    async logout() {
      const userStore = useUserStore();

      try {
        await logoutUser();  // Call the logout function from authService.js
        userStore.clearUser(); // Clear user from Pinia store
        this.$router.push('/signin') // Redirect to Signin page
        localStorage.clear(); // Clear any localStorage or session data if used
      } catch (error) {
        console.error("Error signing out:", error);
      }
    },
  },
  components: {
    Breadcrumbs
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    user() {
      // Get user data from Pinia store
      const userStore = useUserStore();
      return userStore.user;
    },
  }
};
</script>
