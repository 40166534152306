import { defineStore } from 'pinia';
import {useGlobalStore} from '../store/globalStore';
import { updateUserInFirestore, getUserFromFirestore } from '../dbService';
import { auth } from '../main'; // Import Firebase auth to check login status

export const useExpenseStore = defineStore('expense', {
  state: () => { // defined expenseList array to store data
    const globe = useGlobalStore();
    return {
      expenseList: [
        {
          id: 1,
          name: "Food expense",
          amount: 10000,
          frequency: "Monthly",
          from: globe.currentAge,
          till: globe.livetillAge,
          change: globe.inflationRate,
          changetype: "Match Inflation",
          icon: "fa fa-cutlery",
          earner: "Spouse",
          type: "food"
        },
        {
          id: 2,
          name: "Rent",
          amount: 20000,
          frequency: "Monthly",
          from: globe.currentAge,
          till: globe.retirementAge,
          change: globe.inflationRate,
          changetype: "Match Inflation",
          icon: "fa fa-home",
          earner: "Me",
          type: "housing"
        },
      ]
    }
  },

  actions: {
    async addExpense(expense) { // to add incoming data in expenseList array. this function is called from ModalFood etc.
      this.expenseList.push(expense);

      // Check if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          await this.saveExpenseToFirestore(user.uid); // Autosave the updated expense list by calling function, mentioned below
        } catch (error) {
          console.error("Error autosaving expense: ", error);
        }
      }
    },
    async updateExpense(updatedExpense) { // to update expense

      const index = this.expenseList.findIndex(expense => expense.id === updatedExpense.id);
      if (index !== -1) {
        this.expenseList[index] = updatedExpense;

        // Check if the user is logged in
        const user = auth.currentUser;
        if (user) {
          try {
            console.log("Saving updated expense to Firestore", this.expenseList);
            await this.saveExpenseToFirestore(user.uid); // Autosave the updated expense list
          } catch (error) {
            console.error("Error autosaving expense: ", error);
          }
        }
      }
    },
    async saveExpenseToFirestore(userId) {
      try {
        const expenseData = { expenseList: this.expenseList };
        await updateUserInFirestore(userId, expenseData); // Update the Firestore document with the current expenseList
        console.log("Expense data autosaved successfully");
      } catch (error) {
        console.error("Error saving expense data to Firestore: ", error);
      }
    },
    async fetchExpenseFromFirestore(userId) {
      try {
        const userData = await getUserFromFirestore(userId);
        if (userData && userData.expenseList) {
          this.expenseList = userData.expenseList;
          console.log("Expense data fetched successfully");
        }
      } catch (error) {
        console.error("Error fetching expense data from Firestore: ", error);
      }
    },

    updateExpenseAges() {
      const globalStore = useGlobalStore();

      this.expenseList.forEach((expense) => {
        // Update 'from' if it matches either the old currentAge or old retirementAge
        if (expense.from === globalStore.oldCurrentAge) {
          expense.from = globalStore.currentAge;  // Update to the new currentAge
        } else if (expense.from === globalStore.oldRetirementAge) {
          expense.from = globalStore.retirementAge;  // Update to the new retirementAge
        }

        // Update 'till' if it matches old currentAge, old retirementAge, or oldLivetillAge
        if (expense.till === globalStore.oldCurrentAge) {
          expense.till = globalStore.currentAge;  // Update to the new currentAge
        } else if (expense.till === globalStore.oldRetirementAge) {
          expense.till = globalStore.retirementAge;  // Update to the new retirementAge
        } else if (expense.till === globalStore.oldLivetillAge) {
          expense.till = globalStore.livetillAge;  // Update to the new livetillAge
        }

        // Update 'inflation rate'
        if (expense.changetype === "Match Inflation") {
          expense.change = globalStore.inflationRate;  // Update to the new currentAge
        }
      });

      console.log("Updated expenseList with new from/till values:", this.expenseList);
  
      // Optionally, save the updated expense to Firestore if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          this.saveExpenseToFirestore(user.uid);
        } catch (error) {
          console.error("Error autosaving updated expense ages: ", error);
        }
      }
    }
  },
});
