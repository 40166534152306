<!-- Noslider.vue -->
<template>
  <div ref="slider"></div>
</template>

<script>
import nouislider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    const sliderElement = this.$refs.slider;
    const options = {
      start: [this.value], // This is the default value and not the minimum value
      range: {
        min: this.min,
        max: this.max,
      },
      step: this.step,
      tooltips: {
        to: function(value) {
          // Round the value to the nearest integer and return it as a string
          return Math.round(value).toString();
        }
      },
      connect: [true, false], // Connect the part before the handle
    };

    this.slider = nouislider.create(sliderElement, options);
    // Adding pips configuration
    

    this.slider.on('update', (values, handle) => {
      const newValue = parseFloat(values[handle]); // Convert string to number
      this.$emit('input', newValue); // Emit the 'input' event with the new value

      // Get the handle element and set the value as its title (tooltip)
      const handleElement = sliderElement.querySelector('.noUi-handle');
      handleElement.setAttribute('title', newValue.toString());

    });
    
  },
  watch: {
    value(newValue) {
      this.slider.set([newValue]);
    },
  },
  beforeUnmount() {
    if (this.slider) {
      this.slider.destroy();
    }
  },
};
</script>

<style scoped>
/* Add any custom styling here */
/* Display the value as a tooltip on the handle */
::v-deep .noUi-connect {
  background: primary !important; /* Use !important to ensure override */
}
.noUi-handle::before {
  content: attr(title);
  position: absolute;
  top: -30px; /* Adjust the positioning as needed */
  left: 50%;
  transform: translateX(-50%);
}

</style>
