import { defineStore } from 'pinia';
import { updateUserInFirestore, getUserFromFirestore } from '../dbService';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    gender: 'Male',
    genderIcon: 'fa fa-male', // Initialize with the default male icon
    maritalStatus: 'Married',
    spouseGender: 'Female',
    spouseGenderIcon:'fa fa-female',
    currentAge: 30,
    retirementAge: 60,
    livetillAge:80,
    oldCurrentAge: null,  // Add this to store previous currentAge
    oldRetirementAge: null,  // Add this to store previous retirementAge
    oldLivetillAge: null, // Add this to store previous age
    spouseCurrentAge:26,
    spouseRetirementAge:60,
    spouseLiveTillAge:80,
    inflationRate: 5, // default values, you can change these
    returnRate: 10,
  }),
  getters: {
    currAge() {
      return this.currentAge;
    },
    retireAge() {
      return this.retirementAge;
    },
    getGenderIcon() {
      return this.genderIcon;
    },
    getmaritalStatus() {
      return this.maritalStatus;
    },
    getSpouseGenderIcon() {
      return this.spouseGenderIcon;
    },
    ageAndYearArray() {
      const result = [];
      for (let age = this.currentAge; age <= this.livetillAge; age++) {
        result.push({ age, year: new Date().getFullYear() + (age - this.currentAge) });
      }
      return result;
    },
    ageAndYearArrayStartingFromNextYear() {
      const result = [];
      const currentYear = new Date().getFullYear(); // Get the current year
  
      // Start from the next year (current year + 1)
      for (let age = this.currentAge; age < this.livetillAge; age++) { // earlier it was age <= this.livetillAge becuase of which the till was showing options 1 year extra from life expectancy
        result.push({ age: age + 1, year: currentYear + (age - this.currentAge) + 1, period:(age - this.currentAge) + 1 });
      }
  
      return result;
    },
  },
  actions: {
    setGenderIcon(iconClass) {
      this.genderIcon = iconClass;
    },

    updateAges(newCurrentAge, newRetirementAge, newLivetillAge, newInflationRate) {
      // Store the old values before updating
      this.oldCurrentAge = this.currentAge;
      this.oldRetirementAge = this.retirementAge;
      this.oldLivetillAge = this.livetillAge;

      // Now update to the new values
      this.currentAge = newCurrentAge;
      this.retirementAge = newRetirementAge;
      this.livetillAge = newLivetillAge;
      // Update inflation
      this.inflationRate = newInflationRate;
    },

    async saveGlobalStoreToFirestore(userId) {
      // Log globalStore before saving
      
    
      const globalData = {
        gender: this.gender,
        genderIcon: this.genderIcon,
        spouseGender: this.spouseGender,
        spouseGenderIcon: this.spouseGenderIcon,
        currentAge: this.currentAge,
        retirementAge: this.retirementAge,
        livetillAge: this.livetillAge,
        spouseCurrentAge: this.spouseCurrentAge,
        spouseRetirementAge: this.spouseRetirementAge,
        spouseLiveTillAge: this.spouseLiveTillAge,
        inflationRate: this.inflationRate,
        returnRate: this.returnRate
      };
    
      // Log the globalData object before saving to Firestore
      
    
      // Ensure globalData is not undefined before updating Firestore
      if (!globalData || Object.values(globalData).some(value => value === undefined)) {
        
        throw new Error('Cannot save globalStore: contains undefined values');
      }
    
      await updateUserInFirestore(userId, { globalStore: globalData });
    },

    async fetchGlobalDataFromFirestore(userId) {
      try {
        const userData = await getUserFromFirestore(userId);
        if (userData && userData.globalStore) {
          const globalData = userData.globalStore;
          this.gender = globalData.gender;
          this.genderIcon = globalData.genderIcon;
          this.maritalStatus = globalData.maritalStatus;
          this.spouseGender = globalData.spouseGender;
          this.spouseGenderIcon = globalData.spouseGenderIcon;
          this.currentAge = globalData.currentAge;
          this.retirementAge = globalData.retirementAge;
          this.livetillAge = globalData.livetillAge;
          this.spouseCurrentAge = globalData.spouseCurrentAge;
          this.spouseRetirementAge = globalData.spouseRetirementAge;
          this.spouseLiveTillAge = globalData.spouseLiveTillAge;
          this.inflationRate = globalData.inflationRate;
          this.returnRate = globalData.returnRate;
          
        }
      } catch (error) {
        console.error('Error fetching global store from Firestore: ', error);
      }
    },
  },
});
