<template>
  <div class="icon-title-card card-border position-relative" @click="emitModalEvent">
    
    <!-- Delete icon row -->
    <div class="row justify-content-end">
      <div class="col-auto"> <!-- This will align the delete icon to the end -->
        <i class="fa fa-times delete-icon" @click.stop="deleteSaving"></i>
      </div>
    </div>
    
    <div class="row align-items-center g-3 mt-0" style="width: 100%;">
      <div class="col-auto custom-col-width1">
        <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
          <i class="text-lg opacity-10" style="color: white;" :class="getIconCode()" aria-hidden="true"></i>
        </div>
      </div>
      <div class="col custom-col-width2">
        <h6 class="mb-0 text-sm text-uppercase font-weight-bold">{{ saving.name }}</h6>
        <p class="text-muted mb-1" style="font-size: 14px;"><i class="fa fa-clock-o"></i> {{ getFromText() }} till {{ getFromText2() }} </p>
        <p v-if="showChangeTypeAndValue()" class="text-muted mb-1" style="font-size: 14px;"> <i :class="'fa fa-long-arrow-up'"></i> {{ getchangeValue() }}% || {{ saving.annualIncrement }}%</p>
      </div>
      <div class="col custom-col-width3">
        <h6 class="mb-0 text-sm text-uppercase font-weight-bold text-end">{{ formatIndianNumber(saving.amount) }} || {{ formatIndianNumber(saving.newAmount) }}</h6>
        <p class="text-muted mb-1 text-end" style="font-size: 14px;">{{ saving.frequency }}</p>
        <p class="text-muted mb-1 text-end" style="font-size: 14px;"><i :class="[getEarnerIcon(), selectedOptionColorClass]"></i></p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { useGlobalStore } from '../../store/globalStore'; // Adjust the import path as needed
import { useSavingStore } from '../../store/savings'; // Import the saving store for deleting the saving entry from saving store

export default {
  name: "IconTitleCard",
  props: {
    saving: Object, // The saving object to display
    type: String, // type can be 'fd', 'mf', etc.
  },
  computed: {
    selectedOptionColorClass() {
      // Logic to determine color based on the earner
      if (this.saving.earner === "Me") {
        return 'me-color';
      } else if (this.saving.earner === "Spouse") {
        return 'spouse-color';
      } else {
        // Default color or another logic for when no option is selected or for "Joint"
        return 'joint-color';
      }
    },
  },
  methods: {
    emitModalEvent() {
      let modalName = '';
      switch (this.saving.type) {
        case 'fd':
          modalName = 'ModalSavFD';
          break;
        case 'rd':
          modalName = 'ModalSavRD';
          break;
        case 'mf':
          modalName = 'ModalSavMF';
          break;
        case 'stocks':
          modalName = 'ModalSavStocks';
        break;
        case 'gold':
          modalName = 'ModalSavGold';
        break;
        case 'pf':
          modalName = 'ModalSavPF';
        break;
        case 'nps':
          modalName = 'ModalSavNPS';
        break;
        case 'custom':
          modalName = 'ModalSavCustom';
        break;
        case 'cash':
          modalName = 'ModalSavCash';
        break;
        // Add more cases as needed for other types
      }
      this.$emit('show-modal', modalName, this.saving);
    },
    showChangeTypeAndValue() {
      if (this.saving.category === 'Loan') {
        return false; // Don't show if the category is 'Loan'
      } else if ((this.saving.category === 'Housing' || this.saving.category === 'Conveyance') && this.saving.ownershiptype === 'Owned - On Mortgage') {
        return false; // Don't show if the category is 'Housing' or 'Conveyance' and ownership is 'Owned - On Mortgage'
      } else {
        return true; // Show for other cases
      }
    },
    getFromText() {
      if (this.saving.from === 'Current Age') {
        return 'Now';
      } else if (this.saving.from === 'Retirement Age') {
        return 'Retirement';
      } else {
        return this.saving.from;
      }
    },
    getFromText2() {
      if (this.saving.till === 'Current Age') {
        return 'Now';
      } else if (this.saving.till === 'Retirement Age') {
        return 'Retirement';
      } else if (this.saving.till === 'Life Expectancy') {
        return 'Life'; 
      } else {
        return this.saving.till;
      }
    },
    formatIndianNumber(number) {
      var x = number;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
          lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    },
    getEarnerIcon() {
      const store = useGlobalStore();
      if (this.saving.earner === 'Me') {
        return store.getGenderIcon;
      } else if (this.saving.earner === 'Spouse') {
        return store.getSpouseGenderIcon;
      } else {
        return 'fa fa-home';
      }
    },
    getchangeValue() {
      return this.saving.change;
    },
    getIconCode() {
      return this.saving.icon;
    },
    deleteSaving(event) {
      // Access the saving store instance
      event.stopPropagation(); // Stop the event from bubbling up
      const savingStore = useSavingStore();

      // Find the index of the saving entry to delete
      const index = savingStore.savingList.findIndex(
        (item) => item.id === this.saving.id
      );

      // If the saving entry exists in the store, remove it
      if (index !== -1) {
        savingStore.savingList.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.custom-col-width2 {
  flex: 0 0 auto; /* This makes sure your column's width is set explicitly and not just flexing */
  width: 33%; /* or any other value */
}
.custom-col-width2 {
  flex: 0 0 auto; /* This makes sure your column's width is set explicitly and not just flexing */
  width: 35%; /* or any other value */
}

.custom-col-width3 {
  flex: 0 0 auto; /* This makes sure your column's width is set explicitly and not just flexing */
  width: 50%; /* or any other value */
}

.icon-title-card {
  display: flex;
  align-items: center;
  margin-bottom: 1px !important;
  padding-left: 15px !important;
  padding-right: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.delete-icon {
  cursor: pointer; /* Indicates the icon is clickable */
  position: absolute; /* Position the icon absolutely to the top-right */
  top: 0.5rem; /* Adjust the top position as needed */
  right: 1rem; /* Adjust the right position as needed */
  padding-right: 5px !important;
  font-size: 0.75rem; /* Reduced size of the delete icon */
  z-index: 10; /* Ensure the icon is above other elements */
}


.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Adjust the width as needed */
  min-width: 30px;
  height: 30px; /* Adjust the height as needed */
  /* Default background color background-color: rgb(207, 73, 73); */
}

.card-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 1px !important; /* Adjust the border radius as needed for curved corners */
  margin-bottom: 5px !important; /* Add margin between cards */
}

.title-content {
  display: flex; /* This turns .title-content into a flex container */
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers children vertically within the container */
  padding-left: 1rem; /* Keeps your existing left padding */
}
.title-content p {
  margin-bottom: 0; /* Removes the bottom margin */
}

.me-color {
  color: #007bff; /* Replace with your primary color */
}

.spouse-color {
  color: #b324a0; /* Replace with your secondary color */
}

.joint-color {
  color: #34b25a; /* Replace with your secondary color */
}
</style>