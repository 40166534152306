import { db } from './main';
import { doc, setDoc, getDocs, updateDoc, collection } from "firebase/firestore";

// Function to add user details to Firestore
export const addUserToFirestore = async (userId, userData) => {
  try {
    // Create a reference to the specific document in 'users' collection
    const userDocRef = doc(db, 'users', userId);
    // Set the document with the user data
    await setDoc(userDocRef, {
      ...userData,
      userId: userId // Include userId in the document
    });
    
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

// Function to get user details from Firestore
export const getUserFromFirestore = async (userId) => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    const users = [];
    querySnapshot.forEach((doc) => {
      if (doc.id === userId) {
        users.push(doc.data());
      }
    });
    return users.length ? users[0] : null;
  } catch (e) {
    console.error("Error getting documents: ", e);
  }
};

// Function to update user data in Firestore using userId as document ID for existing users
export const updateUserInFirestore = async (userId, updatedData) => {
  //updates a user’s data in Firestore, specifically their incomeList data. It uses the user’s unique ID (userId) to update their document in the users collection in Firestore
  try {
    // Check if userId is valid
    if (!userId) {
      console.error("Invalid userId in updateUserInFirestore: ", userId);
      return;
    }
    
    
    const userDoc = doc(db, "users", userId);
    await updateDoc(userDoc, updatedData);
    
  } catch (e) {
    console.error("Error updating user: ", e);
  }
};