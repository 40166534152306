import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Goals from "../views/Goals.vue";
import Setup from "../views/Setup.vue";
import Finance from "../views/Finance.vue";
import Analyze from "../views/Analyze.vue";
import RTL from "../views/Rtl.vue";
import initialsetup from "../views/InitialSetup.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/InitialSetup",
    name: "InitialSetup",
    component: initialsetup,
  },  
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/goals",
    name: "Goals",
    component: Goals,
  },
  
  {
    path: "/Setup",
    name: "Setup",
    component: Setup,
  },
  {
    path: "/Finance",
    name: "Finance",
    component: Finance,
  },
  
  {
    path: "/Analyze",
    name: "Analyze",
    component: Analyze,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
