<template>
  <div class="modal" ref="ModalSavings" tabindex="-1" role="dialog"> <!-- ref="myModal" is important as this is referred in openModal() as well as 'See.vue'-->
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document"> <!-- modal-lg increase size on medium and large devices-->
      <div class="modal-content px-md-4"> <!-- p-md-4 is padding on medium and large devices only-->
        
        <div class="modal-header">
          <h4 class="modal-title">Add Savings</h4>
          <!-- Close button or icon can go here -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <div class="row">
            <!-- Use col-md-6 to display two cards per row on medium and larger screens -->
            
            <div class="col-md-6" @click="openModalFD">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-archive'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Fixed Deposit</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalRD">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-coins'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Recurring Deposit</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalMF">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-cubes'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Mutual Fund</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalStocks">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-chart-line'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Stocks</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalGold">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-gem'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Gold</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalCash">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-money-bill'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Cash</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalPF">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-landmark'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Provident Fund</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalNPS">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-paper-diploma'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">National Pension Scheme</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalCustom">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-success border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-wrench'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Custom</h6>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      
      </div>
    </div>
  </div>
  <ModalSavFD ref="ModalSavFDComponent" />
  <ModalSavRD ref="ModalSavRDComponent" />
  <ModalSavMF ref="ModalSavMFComponent" />
  <ModalSavStocks ref="ModalSavStocksComponent" />
  <ModalSavGold ref="ModalSavGoldComponent" />
  <ModalSavPF ref="ModalSavPFComponent" />
  <ModalSavNPS ref="ModalSavNPSComponent" />
  <ModalSavCustom ref="ModalSavCustomComponent" />
  <ModalSavCash ref="ModalSavCashComponent" />
</template>

<script>
import ModalSavFD from './ModalSavFD.vue';
import ModalSavRD from './ModalSavRD.vue';
import ModalSavMF from './ModalSavMF.vue';
import ModalSavStocks from './ModalSavStocks.vue';
import ModalSavGold from './ModalSavGold.vue';
import ModalSavPF from './ModalSavPF.vue';
import ModalSavNPS from './ModalSavNPS.vue';
import ModalSavCustom from './ModalSavCustom.vue';
import ModalSavCash from './ModalSavCash.vue';

export default {
  components : {
    ModalSavFD,
    ModalSavRD,
    ModalSavMF,
    ModalSavStocks,
    ModalSavGold,
    ModalSavPF,
    ModalSavNPS,
    ModalSavCustom,
    ModalSavCash
  },
  methods: {
    openModal() {
      this.$refs.ModalSavings.classList.add('show');
      this.$refs.ModalSavings.style.display = 'block';
      document.body.classList.add('modal-open');
    },
    closeModal() {
      this.$refs.ModalSavings.classList.remove('show');
      this.$refs.ModalSavings.style.display = 'none';
      document.body.classList.remove('modal-open');
    },
    openModalFD() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavFDComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalRD() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavRDComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalMF() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavMFComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalStocks() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavStocksComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalGold() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavGoldComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalPF() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavPFComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalNPS() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavNPSComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalCustom() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavCustomComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },
    openModalCash() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSavCashComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },


    

  }
};
</script>


<style scoped>


.modal {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}



.icon-title-card {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: background-color 0.2s; /* Add a smooth transition effect */
  cursor: pointer; /* Change cursor to a hand icon */
}

.icon-title-card:hover {
  background-color: rgb(203, 234, 197); /* Change the background color on hover */
  /* You can use any color code or class here */
}

.card-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 10px; /* Adjust the border radius as needed for curved corners */
  margin-bottom: 20px; /* Add margin between cards */
}

.card-padding {
  padding: 10px;
  padding-left: 15px;
}

.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px; /* Adjust the width as needed */
  min-width: 48px;
  height: 48px; /* Adjust the height as needed */
  /* Default background color background-color: rgb(207, 73, 73); */
}

.title-content {
  flex-grow: 1;
  padding-left: 1rem; /* Add spacing between the icon and title */
  /* Add any additional styling for the title content here */
}


</style>