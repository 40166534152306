<template>
  <div class="modal" ref="myModal" tabindex="-1" role="dialog"> <!-- ref="myModal" is important as this is referred in openModal() as well as 'See.vue'-->
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document"> <!-- modal-lg increase size on medium and large devices-->
      <div class="modal-content px-md-4"> <!-- p-md-4 is padding on medium and large devices only-->
        
        <div class="modal-header">
          <h4 class="modal-title">Add Income</h4>
          <!-- Close button or icon can go here -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <div class="row">
            <!-- Use col-md-6 to display two cards per row on medium and larger screens -->
            
            <div class="col-md-6" @click="openModalSalary">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-briefcase-24'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Salary</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalFreelancing">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-money-coins'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Freelancing</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalCommission">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-world'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Rental Income</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalSideBusiness">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-paper-diploma'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Business</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalBonus">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-briefcase-24'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Bonus</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" @click="openModalCustomIncome">
              <div class="icon-title-card card-border card-padding" style="width: 100%;">
                <div class="d-flex align-items-center"> <!-- Add align-items-center here -->
                  <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
                    <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-wrench'" aria-hidden="true"></i>
                  </div>
                  <div class="title-content"> <!--this is text-->
                    <h6 class="mb-0 text-sm text-uppercase font-weight-bold">Custom</h6>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      
      </div>
    </div>
  </div>
  <ModalSalary ref="ModalSalaryComponent" /> <!--Need to add 'Component' to text which is used in opening openModalSalary()-->
  <ModalFreelancing ref="ModalFreelancingComponent" />
  <ModalCommission ref="ModalCommissionComponent" />
  <ModalSideBusiness ref="ModalSideBusinessComponent"/>
  <ModalCustomIncome ref="ModalCustomIncomeComponent"/>
  <ModalBonus ref="ModalBonusComponent"/>
</template>

<script>
import ModalSalary from './ModalSalary.vue';
import ModalFreelancing from './ModalFreelancing.vue';
import ModalCommission from './ModalCommission.vue';
import ModalSideBusiness from './ModalSideBusiness.vue';
import ModalCustomIncome from './ModalCustomIncome.vue';
import ModalBonus from './ModalBonus.vue';

export default {
  components : {
    ModalSalary,
    ModalFreelancing,
    ModalCommission,
    ModalSideBusiness,
    ModalCustomIncome,
    ModalBonus
  },
  methods: {
    
    openModalSalary() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalSalary'
      this.$refs.ModalSalaryComponent.openModal(); // 'ModalSalaryComponent' as used just before last line of template
    },

    openModalFreelancing() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalCommission'
      this.$refs.ModalFreelancingComponent.openModal(); // 'ModalCommissionComponent' as used just before last line of template
    },

    openModalCommission() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalCommission'
      this.$refs.ModalCommissionComponent.openModal(); // 'ModalCommissionComponent' as used just before last line of template
    },

    openModalSideBusiness() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalCommission'
      this.$refs.ModalSideBusinessComponent.openModal(); // 'ModalCommissionComponent' as used just before last line of template
    },
    
    openModalCustomIncome() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalCommission'
      this.$refs.ModalCustomIncomeComponent.openModal(); // 'ModalCommissionComponent' as used just before last line of template
    },

    openModalBonus() {
      // Close 'MyModal' before opening 'ModalSalary'
      this.closeModal();
      // Open 'ModalCommission'
      this.$refs.ModalBonusComponent.openModal(); // 'ModalBonusComponent' as used just before last line of template
    },

    openModal() {
      this.$refs.myModal.classList.add('show');
      this.$refs.myModal.style.display = 'block';
      document.body.classList.add('modal-open');
    },
    closeModal() {
      this.$refs.myModal.classList.remove('show');
      this.$refs.myModal.style.display = 'none';
      document.body.classList.remove('modal-open');
    },

  }
};
</script>


<style scoped>


.modal {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}



.icon-title-card {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: background-color 0.2s; /* Add a smooth transition effect */
  cursor: pointer; /* Change cursor to a hand icon */
}

.icon-title-card:hover {
  background-color: rgb(183, 183, 255); /* Change the background color on hover */
  /* You can use any color code or class here */
}

.card-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 10px; /* Adjust the border radius as needed for curved corners */
  margin-bottom: 20px; /* Add margin between cards */
}

.card-padding {
  padding: 10px;
  padding-left: 15px;
}

.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px; /* Adjust the width as needed */
  min-width: 48px;
  height: 48px; /* Adjust the height as needed */
  /* Default background color background-color: rgb(207, 73, 73); */
}

.title-content {
  flex-grow: 1;
  padding-left: 1rem; /* Add spacing between the icon and title */
  /* Add any additional styling for the title content here */
}


</style>