<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card px-4">
          <div class="card-body">
            <h4 class="mb-4">Setup</h4>
            <!-- Gender cards -->
            <h6>Gender:</h6>
            <div class="d-flex justify-content-around mb-4">
              <div class="card selection-card text-center" @click="selectGender('Male')" :class="{'selected': selectedGender === 'Male'}">
                <div class="card-header bg-gradient-primary">
                  <i class="fa fa-male fa-2x text-white"></i>
                </div>
                <div class="card-body">
                  <h6>Male</h6>
                </div>
              </div>
              <div class="card selection-card text-center" @click="selectGender('Female')" :class="{'selected': selectedGender === 'Female'}">
                <div class="card-header bg-gradient-primary">
                  <i class="fa fa-female fa-2x text-white"></i>
                </div>
                <div class="card-body">
                  <h6>Female</h6>
                </div>
              </div>
            </div>

            <!-- Marital Status cards -->
            <h6>Marital Status:</h6>
            <div class="d-flex justify-content-around mb-4">
              <div class="card selection-card text-center" @click="selectMaritalStatus('Married')" :class="{'selected': selectedMaritalStatus === 'Married'}">
                <div class="card-header bg-gradient-primary">
                  <i class="fa fa-heart fa-2x text-white"></i>
                </div>
                <div class="card-body">
                  <h6>Married</h6>
                </div>
              </div>
              <div class="card selection-card text-center" @click="selectMaritalStatus('Unmarried')" :class="{'selected': selectedMaritalStatus === 'Unmarried'}">
                <div class="card-header bg-gradient-primary">
                  <i class="fa fa-user fa-2x text-white"></i>
                </div>
                <div class="card-body">
                  <h6>Unmarried</h6>
                </div>
              </div>
            </div>

            <!-- Slider section -->
            <div class="mb-4 mt-6">
              <label for="sliderCurrentAge">Current Age:</label>
              <noslider v-model:value="sliderCurrentAgeValue" :min="15" :max="105" :step="1" @input="sliderCurrentAgeValue = $event"></noslider>
            </div>
            <div class="mb-4">
              <label for="sliderRetirementAge">Retirement Age:</label>
              <noslider v-model:value="sliderRetirementAgeValue" :min="15" :max="105" :step="1" @input="sliderRetirementAgeValue = $event"></noslider>
            </div>
            <div class="mb-4">
              <label for="sliderLifeExpectancy">Life Expectancy:</label>
              <noslider v-model:value="sliderLiveTillValue" :min="15" :max="105" :step="1" @input="sliderLiveTillValue = $event"></noslider>
            </div>
            <div class="mb-4">
              <label for="sliderInflation">Inflation Rate (%):</label>
              <noslider v-model:value="inflationRate" :min="0" :max="10" :step="1" @input="inflationRate = $event"></noslider>
            </div>

            <!-- Action buttons -->
            <div class="text-end mt-6 mb-4">
              <argon-button color="success" size="lg" variant="gradient" @click="finish">Save</argon-button>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import noslider from '../components/noslider.vue';
import ArgonButton from '../components/ArgonButton.vue';
import { useGlobalStore } from '../store/globalStore';
import { useIncomeStore } from '../store/income';
import { useExpenseStore } from '../store/expense';
import { useSavingStore } from '../store/savings';
import { auth } from '../main';

export default {

components: {
  noslider,
  ArgonButton,
},
data() {
  const globe = useGlobalStore();
  return {
    sliderCurrentAgeValue: globe.currentAge, // Initial value
    sliderRetirementAgeValue: globe.retirementAge, // Initial value
    sliderLiveTillValue: globe.livetillAge, // Initial value
    inflationRate: globe.inflationRate,
    selectedGender: globe.gender,
    selectedMaritalStatus: globe.maritalStatus, // Added for marital status
    currentStep: 1,
    range: {
      min: 0,
      max: 100,
    },
    
  };
},
methods: {
  finish() {
    const globalStore = useGlobalStore();
      
    globalStore.updateAges(this.sliderCurrentAgeValue, this.sliderRetirementAgeValue, this.sliderLiveTillValue, this.inflationRate);

    globalStore.currentAge = this.sliderCurrentAgeValue;
    globalStore.retirementAge = this.sliderRetirementAgeValue;
    globalStore.livetillAge = this.sliderLiveTillValue;
    globalStore.inflationRate = this.inflationRate;
    globalStore.maritalStatus = this.selectedMaritalStatus;
    if (this.selectedGender === 'Male') {
      globalStore.gender = 'Male';
      globalStore.genderIcon = 'fa fa-male';
      globalStore.spouseGender = 'Female';
      globalStore.spouseGenderIcon = 'fa fa-female';
      // later on set a nested if regarding marital status as unmarried, then leave the spouse detail
    } else if (this.selectedGender === 'Female') {
      globalStore.gender = 'Female';
      globalStore.genderIcon = 'fa fa-female';
      globalStore.spouseGender = 'Male';
      globalStore.spouseGenderIcon = 'fa fa-male';
      // later on set a nested if regarding marital status as unmarried, then leave the spouse detail
    }

    const user = auth.currentUser;

    // After updating globalStore, to update values in incomeList etc.
    const incomeStore = useIncomeStore();
    const expenseStore = useExpenseStore();
    const savingStore = useSavingStore();
    incomeStore.updateIncomeAges();
    expenseStore.updateExpenseAges();
    savingStore.updateSavingAges();

    if (!user) { 
      this.$router.push('/Finance')
    } else {
      const userId = user.uid;
      globalStore.saveGlobalStoreToFirestore(userId);
      this.$router.push('/Finance')
    }
  },

  selectGender(gender) {
    // Toggle gender selection
    this.selectedGender = gender;
  },
  selectMaritalStatus(status) {
    // Toggle marital status selection
    this.selectedMaritalStatus = status;
  },
},
};
</script>

<style>
.selection-card {
  width: 140px; /* Adjust width as needed */
  height: 160px; /* Adjust height as needed */
  margin: 0 10px; /* Ensure cards don’t touch each other */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.selection-card .fa {
  font-size: 1.5rem; /* Adjust icon size as needed */
}

.selection-card h6 {
  font-size: 0.9rem; /* Adjust text size as needed */
}

.selection-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

/* Style for the selected state of the card-header */
.selection-card.selected .card-header {
  background-color: #28a745; /* Darker green for selected state */

}

/* Style for unselected card header on hover */
.selection-card:not(.selected) .card-header:hover {
  background-color: #2dcba6; /* Lighter green on hover */
}

.card-header {
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

/* Adjustments if you want the whole card to change color, not just the header */
.selection-card.selected {
  background-color: #f4f4f4; /* Lighter background for the whole card when selected. This is causing the greenish tint. This is main */
  border: 1px solid #8f57aa; /* Green border for selected state */
}

/* Maintain the selected card color on hover for the entire card */
.selection-card.selected:hover {
  box-shadow: none; /* Optional: remove the shadow on hover for selected cards */
}

/* Ensures that the hover state doesn't override the selected state background */
.selection-card.selected:hover .card-header {
  background-color: #8f57aa;
}

/* Maintain the border color for the selected card */
.selection-card.selected,
.selection-card.selected:hover {
  border-color: #8f57aa;
}
</style>

