<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item mb-4">
        <sidenav-item
          url="/Finance"
          :class="getRoute() === 'Finance' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'تجربة' : 'Budgeting'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item mb-4">
        <sidenav-item
          url="/Analyze"
          :class="getRoute() === 'Analyze' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'تجربة' : 'Analysis'"
        >
          <template v-slot:icon>
            <i class="ni ni-chart-pie-35 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item mb-4">
        <sidenav-item
          url="/goals"
          :class="getRoute() === 'goals' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'تجربة' : 'Goals'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-bullseye text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/Setup"
          :class="getRoute() === 'TrialTwo' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'تجربة' : 'Setup'"
        >
          <template v-slot:icon>
            <i class="ni ni-settings-gear-65 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      
    </ul>
  </div>
  
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    SidenavItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>

<style scoped>

.sidenav {
    overflow-y: auto; /* Vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.navbar-vertical .navbar-nav {
    overflow: visible; /* Child containers shouldn't scroll */
}

</style>
