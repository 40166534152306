<template>
  <div class="py-4 container-fluid">
    <div class="container mt-5">
      <h2 class="text-center text-dark text-gradient mb-4">Plan and Track Your Financial Goals</h2>

      <div class="row mt-4">
        <div class="col-md-12 mb-4">
          <div class="card">
            <!-- Card Header with Goal Toggle and Add New Goal Button -->
            <div class="card-header pb-0 px-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
              <!-- Goal Toggle Buttons -->
              <div class="goal-toggle mb-4 mb-md-0" v-if="!isAddingGoal">
                <button 
                  :class="{'active': showActiveGoals}" 
                  @click="showActiveGoals = true">
                  Active Goals
                </button>
                <button 
                  :class="{'active': !showActiveGoals}" 
                  @click="showActiveGoals = false">
                  Achieved Goals
                </button>
              </div>

              <!-- Add New Goal Button -->
              <argon-button v-if="!isAddingGoal" color="dark" variant="gradient" @click="startAddingGoal">
                <i class="fas fa-plus me-2"></i>
                Add New Goal
              </argon-button>
            </div>

            <!-- Card Body -->
            <div class="card-body pt-4 p-3">
              
              <!-- Show New Goal Form if isAddingGoal is true -->
              <div v-if="isAddingGoal">
                <div class="row" v-if="!isGoalSelected">
                  <h6 class="mb-4">What is your goal?</h6>
                  <div class="row">
                    <div class="col-md-3" v-for="goalType in goalTypes" :key="goalType" @click="selectGoalType(goalType)"
                      :class="{'selected': selectedGoalType === goalType}">
                      <div class="card clickable mb-2">
                        <div class="card-body d-flex align-items-center">
                          <i :class="getGoalIcon(goalType)" class="w-10 me-3 mb-0" style="color: #6f42c1;"></i>
                          <h6 class="card-title">{{ getGoalTitle(goalType) }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div v-if="selectedGoalType" class="mt-4">
                  <h6>Name your Goal</h6>
                  <input type="text" v-model="goalName" class="form-control" placeholder="e.g., My Dream Car" required />

                  <h6 class="mt-3">What is the Cost?</h6>
                  <input type="number" v-model="goalBudget" class="form-control" placeholder="Enter budget amount" required />

                  <h6 class="mt-3">When do you plan for?</h6>
                  <input type="month" v-model="goalDate" class="form-control" required />

                  <!-- Purchase Type Radio Buttons -->
                  <h6 class="mt-3">Way of purchase?</h6>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="purchaseType" value="Lumpsum" required />
                    <label class="form-check-label">Lumpsum</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="purchaseType" value="EMI" required />
                    <label class="form-check-label">EMI</label>
                  </div>

                  <!-- Conditional Calculations Based on Purchase Type -->
                  
                    <div v-if="purchaseType === 'Lumpsum'">
                      <p>For {{ monthsToSave }} months, I need to save {{ monthlySaving }} per month.</p>
                    </div>
                    <div v-if="purchaseType === 'EMI'">
                      <p>Downpayment is 15%, EMI will be {{ emiAmount }} @10% p.a for 7 years.</p>
                    </div>
                  

                  <!-- Save or Cancel Buttons -->
                  <div class="d-flex justify-content-between mt-4">
                    <button class="btn btn-success" @click="saveGoal">Save Goal</button>
                    <button class="btn btn-secondary" @click="cancelAddingGoal">Cancel</button>
                  </div>
                </div>
              </div>

              <!-- Show Goals if isAddingGoal is false -->
              <div v-else>
                <div class="row">
                  <div class="col-md-4" v-for="goal in (showActiveGoals ? activeGoals : achievedGoals)" :key="goal.id">
                    <div class="card mb-3">
                      
                      <div class="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h6 class="card-title">{{ goal.name }}</h6>
                        <i :class="getGoalIcon(goal.type)" class="w-10 me-3 mb-0" style="color: #6f42c1;"></i>
                      </div>
                      
                      <div class="card-body d-flex align-items-start">
                        <div>
                          <p class="card-text">Budget: {{ goal.budget }}</p>
                          <p class="card-text">Target Date: {{ goal.date }}</p>
                          <p class="card-text">Purchase Type: {{ goal.purchaseType }}</p>
                          <button v-if="showActiveGoals" class="btn btn-sm btn-success mt-2" @click="markAsAchieved(goal.id)">
                            Mark as Achieved
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(showActiveGoals ? activeGoals : achievedGoals).length === 0" class="col-12 text-center">
                    <p>{{ showActiveGoals ? 'No active goals currently' : 'No achieved goals yet' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ArgonButton from '../components/ArgonButton.vue';
// import ArgonInput from '../components/ArgonInput.vue';
import { useGoalStore } from '../store/goalStore';


export default {
  name: 'Goals',
  components: {
    // ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      showActiveGoals: true, // Controls whether to show active or achieved goals
      isAddingGoal: false,
      isGoalSelected: false,
      selectedGoalType: "",
      goalName: "",
      goalBudget: 0,
      goalDate: "",
      purchaseType: "Lumpsum",
      goalTypes: [
        'travel',
        'dreamCar',
        'childEducation',
        'retirement',
        'buyHouse',
        'custom',
      ],
    };
  },
  computed: {
    monthsToSave() {
      const selectedMonth = new Date(this.goalDate);
      const currentMonth = new Date();
      return (selectedMonth.getFullYear() - currentMonth.getFullYear()) * 12 + (selectedMonth.getMonth() - currentMonth.getMonth());
    },
    monthlySaving() {
      return this.goalBudget / this.monthsToSave;
    },
    activeGoals() {
      const goalStore = useGoalStore();
      return goalStore.activeGoals;
    },
    achievedGoals() {
      const goalStore = useGoalStore();
      return goalStore.achievedGoals;
    },
  },
  methods: {
    getGoalIcon(goalType) {
      switch (goalType) {
        case 'travel':
          return 'fa fa-plane';
        case 'dreamCar':
          return 'fa fa-car';
        case 'childEducation':
          return 'fa fa-graduation-cap';
        case 'retirement':
          return 'fa fa-birthday-cake';
        case 'buyHouse':
          return 'fa fa-home';
        case 'custom':
          return 'fa fa-shopping-bag';
        default:
          return ''; // Fallback in case of unknown goalType
      }
    },
    getGoalTitle(goalType) {
      switch (goalType) {
        case 'travel':
          return 'Travel';
        case 'dreamCar':
          return 'Dream Car Purchase';
        case 'childEducation':
          return "Child's Education";
        case 'retirement':
          return 'Retirement';
        case 'buyHouse':
          return 'Buy a House';
        case 'custom':
          return 'Custom';
        default:
          return ''; // Fallback in case of unknown goalType
      }
    },
    toggleGoalView() {
      this.showActiveGoals = !this.showActiveGoals;
    },
    markAsAchieved(goalId) {
      const goalIndex = this.activeGoals.findIndex(goal => goal.id === goalId);
      if (goalIndex !== -1) {
        const achievedGoal = this.activeGoals.splice(goalIndex, 1)[0];
        this.achievedGoals.push(achievedGoal);
      }
    },
    
    startAddingGoal() {
      this.isAddingGoal = true;
    },
    selectGoalType(goalType) {
      this.selectedGoalType = goalType;
      this.isGoalSelected = true;
    },
    cancelAddingGoal() {
      this.isAddingGoal = false;
      this.isGoalSelected = false;
      this.resetForm();
    },
    resetForm() {
      this.selectedGoalType = "";
      this.goalName = "";
      this.goalBudget = 0;
      this.goalDate = "";
      this.purchaseType = "Lumpsum";
    },
    saveGoal() {
      const newGoal = {
        id: Date.now(),
        name: this.goalName,
        type: this.selectedGoalType,
        budget: this.goalBudget,
        date: this.goalDate,
        purchaseType: this.purchaseType,
      };
      const goalStore = useGoalStore();
      goalStore.addGoal(newGoal);
      this.isAddingGoal = false;
      this.isGoalSelected = false;
      this.resetForm();
    },
    
  },
};
</script>

<style scoped>

.clickable {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer; /* Change cursor to pointer on hover */
  outline: none; /* Remove blue outline */
}

.clickable:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.selected {
  transform: scale(1); /* Avoid scaling when selected */
  box-shadow: none; /* Remove shadow when selected */
}

label {
  display: block;
}
input[type="number"],
input[type="text"],
input[type="month"] {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
}


.goal-toggle {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #ddd;
  width: 250px; /* Adjust to your preferred width */
  height: 40px; /* Adjust to your preferred height */
}

.goal-toggle button {
  flex: 1;
  padding: 5px 10px;
  border: none;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  font-size: 14px; /* Adjust font size to make it smaller */
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.goal-toggle button.active {
  background-color: #6f42c1; /* Purple color */
  /* background-color: var(--bs-primary); */
  color: #fff;
}

.goal-toggle button:not(.active) {
  background-color: #fff;
  color: #000;
}

.goal-toggle button:focus {
  outline: none;
}

.goal-toggle button:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.goal-toggle button:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

</style>

