<template>
    <div class="dropdown">
      <button
        class="btn btn-bg-white dropdown-toggle"
        type="button"
        :id="dropdownId"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="width: 100%; text-align: left;"
      >
        <i :class="selectedOptionIconClass"></i> <span class="icon-text-spacing">{{ selectedOption || placeholder }}</span>
        
      </button>
      <ul class="dropdown-menu fixed-width" aria-labelledby="dropdownMenuButton">
        <li v-for="option in options" :key="option">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectOption(option)"
          >
            <i :class="getOptionIconClass(option)"></i> <span class="icon-text-spacing">{{ option }}</span>
          </a>
        </li>
      </ul>
    </div>
</template>
  
<script>
export default {
    props: {
      placeholder: {
        type: String,
        default: "Select an option",
      },
      dropdownId: {
        type: String,
        required: true,
      },
      defaultValue: {
        type: String, // Assuming the default value is a string
        default: null, // No default value initially
      },
    },
    data() {
      return {
        options: ["Annual", "Monthly", "Quarterly", "Half-Yearly"], // Define options internally
        selectedOption: this.defaultValue, // Initialize selectedOption with defaultValue
      };
    },
    computed: {
      selectedOptionIconClass() {
        if (this.selectedOption === "Quarterly") {
          return "fa fa-calendar-plus-o";
        } else if (this.selectedOption === "Half-Yearly") {
          return "fa fa-calendar-minus-o";
        } else if (this.selectedOption === "Monthly") {
          return "fa fa-calendar-check-o";  
        } else if (this.selectedOption === "Annual") {
          return "fa fa-calendar";
        } else {
          return ""; // No icon for other options
        }
      },
    },
    methods: {
      selectOption(option) {
        this.selectedOption = option;
        this.$emit("selected", option);
      },
      getOptionIconClass(option) {
        if (option === "Quarterly") {
          return "fa fa-calendar-plus-o";
        } else if (option === "Half-Yearly") {
          return "fa fa-calendar-minus-o";
        } else if (option === "Monthly") {
          return "fa fa-calendar-check-o";  
        } else if (option === "Annual") {
          return "fa fa-calendar";
        } else {
          return ""; // No icon for other options
        }
      },
    },
};
</script>
  
<style scoped>
/* Add custom CSS to make the dropdown full width */
.fixed-width {
width: 100% !important; /* Adjust the width as needed */
}
.icon-text-spacing {
  margin-left: 10px; /* Adjust the margin as needed to create space between icon and text */
}
</style>
  