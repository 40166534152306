<template>
  <div class="icon-title-card card-border position-relative" @click="emitModalEvent">
    
    <!-- Delete icon row -->
    <div class="row justify-content-end">
      <div class="col-auto"> <!-- This will align the delete icon to the end -->
        <i class="fa fa-times delete-icon" @click.stop="deleteIncome"></i>
      </div>
    </div>
    
    <div class="row align-items-center g-3 mt-0" style="width: 100%;">
      <div class="col-auto">
        <div class="icon-background bg-gradient-primary border-radius-2xl"> <!--this is icon with background-->
          <i class="text-lg opacity-10" style="color: white;" :class="getIconCode()" aria-hidden="true"></i>
        </div>
      </div>
      <div class="col custom-col-width">
        <h6 class="mb-0 text-sm text-uppercase font-weight-bold">{{ income.name }}</h6>
        <p class="text-muted mb-1" style="font-size: 14px;"><i class="fa fa-clock-o"></i> {{ getFromText() }} till {{ getFromText2() }} </p>
        <p class="text-muted mb-1" style="font-size: 14px;"> <i :class=getchangeType()></i> {{ getchangeValue() }}% Annual</p>
      </div>
      <div class="col">
        <h6 class="mb-0 text-sm text-uppercase font-weight-bold text-end">{{ formatIndianNumber(income.amount) }}</h6>
        <p class="text-muted mb-1 text-end" style="font-size: 14px;">{{ income.frequency }}</p>
        <p class="text-muted mb-1 text-end" style="font-size: 14px;"><i :class="[getEarnerIcon(), selectedOptionColorClass]"></i></p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { useGlobalStore } from '../../store/globalStore'; // Adjust the import path as needed
import { useIncomeStore } from '../../store/income'; // Import the income store for deleting the income entry from income store


export default {
  name: "IconTitleCard",
  props: {
    income: Object, // The income object to display
    type: String, // type can be 'salary', 'freelancing', 'commission', etc.
  },
  computed: {
    selectedOptionColorClass() {
      // Logic to determine color based on the earner
      if (this.income.earner === "Me") {
        return 'me-color';
      } else if (this.income.earner === "Spouse") {
        return 'spouse-color';
      } else {
        // Default color or another logic for when no option is selected or for "Joint"
        return 'joint-color';
      }
    },
  },
  methods: {
    emitModalEvent() {
      let modalName = '';
      switch (this.income.type) {
        case 'salary':
          modalName = 'ModalSalary';
          break;
        case 'freelancing':
          modalName = 'ModalFreelancing';
          break;
        case 'commission':
          modalName = 'ModalCommission';
          break;
        case 'sidebusiness':
          modalName = 'ModalSideBusiness';
        break;
        case 'customincome':
          modalName = 'ModalCustomIncome';
        break;
        case 'bonus':
          modalName = 'ModalBonus';
        break;
        // Add more cases as needed for other types
      }
      this.$emit('show-modal', modalName, this.income);
    },
    getFromText() {
      if (this.income.from === 'Current Age') {
        return 'Now';
      } else if (this.income.from === 'Retirement Age') {
        return 'Retirement';
      } else {
        return this.income.from;
      }
    },
    getFromText2() {
      if (this.income.till === 'Current Age') {
        return 'Now';
      } else if (this.income.till === 'Retirement Age') {
        return 'Retirement';
      } else {
        return this.income.till;
      }
    },
    formatIndianNumber(number) {
      var x = number;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
          lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    },
    getEarnerIcon() {
      const store = useGlobalStore();
      if (this.income.earner === 'Me') {
        return store.getGenderIcon;
      } else if (this.income.earner === 'Spouse') {
        return store.getSpouseGenderIcon;
      } else {
        return 'fa fa-home';
      }
    },
    getchangeValue() {
      return this.income.change;
    },
    getchangeType() {
      if (this.income.changetype === 'Increase') {
        return 'fa fa-long-arrow-up';
      } else if (this.income.changetype === 'Decrease') {
        return 'fa fa-long-arrow-down';
      } else {
        return 'fa fa-long-arrow-right';
      }
    },
    getIconCode() {
      return this.income.icon;
    },
    deleteIncome(event) {
      // Access the income store instance
      event.stopPropagation(); // Stop the event from bubbling up
      const incomeStore = useIncomeStore();

      // Find the index of the income entry to delete
      const index = incomeStore.incomeList.findIndex(
        (item) => item.id === this.income.id
      );

      // If the income entry exists in the store, remove it
      if (index !== -1) {
        incomeStore.incomeList.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>

.custom-col-width {
  flex: 0 0 auto; /* This makes sure your column's width is set explicitly and not just flexing */
  width: 60%; /* or any other value */
}
.icon-title-card {
  display: flex;
  align-items: center;
  margin-bottom: 1px !important;
  padding-left: 15px !important;
  padding-right: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.delete-icon {
  cursor: pointer; /* Indicates the icon is clickable */
  position: absolute; /* Position the icon absolutely to the top-right */
  top: 0.5rem; /* Adjust the top position as needed */
  right: 1rem; /* Adjust the right position as needed */
  padding-right: 5px !important;
  font-size: 0.75rem; /* Reduced size of the delete icon */
  z-index: 10; /* Ensure the icon is above other elements */
}


.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Adjust the width as needed */
  min-width: 30px;
  height: 30px; /* Adjust the height as needed */
  /* Default background color background-color: rgb(207, 73, 73); */
}

.card-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 1px !important; /* Adjust the border radius as needed for curved corners */
  margin-bottom: 5px !important; /* Add margin between cards */
}

.title-content {
  display: flex; /* This turns .title-content into a flex container */
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers children vertically within the container */
  padding-left: 1rem; /* Keeps your existing left padding */
}
.title-content p {
  margin-bottom: 0; /* Removes the bottom margin */
}

.me-color {
  color: #007bff; /* Replace with your primary color */
}

.spouse-color {
  color: #b324a0; /* Replace with your secondary color */
}

.joint-color {
  color: #34b25a; /* Replace with your secondary color */
}
</style>