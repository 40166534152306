import { defineStore } from 'pinia';

export const useGoalStore = defineStore('goalStore', {
  state: () => ({
    activeGoals: [],
    achievedGoals: [],
  }),
  actions: {
    addGoal(goal) {
      // Add the new goal to the active goals list
      this.activeGoals.push(goal);
    },
    achieveGoal(goalId) {
      // Move goal from active to achieved
      const goalIndex = this.activeGoals.findIndex(goal => goal.id === goalId);
      if (goalIndex !== -1) {
        const goal = this.activeGoals.splice(goalIndex, 1)[0];
        this.achievedGoals.push(goal);
      }
    },
    removeGoal(goalId) {
      // Remove the goal from active or achieved goals
      this.activeGoals = this.activeGoals.filter(goal => goal.id !== goalId);
      this.achievedGoals = this.achievedGoals.filter(goal => goal.id !== goalId);
    },
    getGoalById(goalId) {
      // Fetch a specific goal by ID (from active or achieved)
      return this.activeGoals.find(goal => goal.id === goalId) || 
             this.achievedGoals.find(goal => goal.id === goalId);
    },
  },
});