<template>
  
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      :style="{ backgroundImage: `url(${signupCoverImage})`, backgroundPosition: 'center', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}"
      
    >
      <span class="mask bg-gradient-dark opacity-0"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h6
              class="text-dark"
            >"Let's go invent tomorrow rather than worrying about what happened yesterday" - Steve Jobs</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4"> <!-- 'Register with' text -->
              <h5>Register with</h5>
            </div>
            <div class="row justify-content-center"> <!-- Google logo-->
              <div class="col-3 d-flex justify-content-center align-items-center text-center">
                <a class="btn btn-outline-light" @click="signInWithGoogle" href="javascript:;">
                  <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(3.000000, 2.000000)" fill-rule="nonzero">
                        <path
                          d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                          fill="#4285F4"
                        />
                        <path
                          d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                          fill="#34A853"
                        />
                        <path
                          d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                          fill="#FBBC05"
                        />
                        <path
                          d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                          fill="#EB4335"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div class="mt-2 position-relative text-center"> <!-- line for 'or' -->
                <p
                  class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                >or</p>
              </div>
            <div class="card-body">
              <form role="form" @submit.prevent="register">
                <argon-input v-model:value="name" type="text" placeholder="Name" aria-label="Name" />
                <argon-input v-model:value="email" type="email" placeholder="Email" aria-label="Email" />
                <argon-input v-model:value="password" type="password" placeholder="Password" aria-label="Password" />
                <argon-checkbox checked>
                  <label class="form-check-label" for="flexCheckDefault">
                    I agree to the
                    <a href="javascript:;" class="text-dark font-weight-bolder">Terms and Conditions</a>
                  </label>
                </argon-checkbox>
                <div class="text-center">
                  <argon-button fullWidth color="dark" variant="gradient" class="my-4 mb-2" type="submit">Sign up</argon-button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  Already have an account?
                  <a href="javascript:;" class="text-dark font-weight-bolder" @click="gotoSignin">Sign in</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  
</template>

<script>

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import { registerUser, signInWithGoogle } from '../authService.js';
import { useUserStore } from '../store/useUserStore';
import { addUserToFirestore } from '../dbService'; // to store user name and email id in database
import { useIncomeStore } from "../store/income";
import { useExpenseStore } from "../store/expense";
import { useSavingStore } from "../store/savings";
import { useGlobalStore } from "../store/globalStore";


export default {
  name: "signup",
  components: {
    ArgonInput,
    ArgonCheckbox,
    ArgonButton,
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      signupCoverImage: require('../assets/img/illustrations/city.png'), // Path to the local image
    };
  },
  methods: {
    async register() {
      try {
        if (!this.email || !this.password || !this.name) {
          throw new Error('Name, email and password are required');
        }
        const userCredential = await registerUser(this.email, this.password);
        const user = userCredential.user;

        // Save user to Firestore
        await addUserToFirestore(user.uid, { name: this.name, email: this.email });

        // Retrieve the temporarily saved incomeList from localStorage
        const unsavedIncomeList = JSON.parse(localStorage.getItem("unsavedIncomeList"));
        const unsavedExpenseList = JSON.parse(localStorage.getItem("unsavedExpenseList"));
        const unsavedSavingList = JSON.parse(localStorage.getItem("unsavedSavingList"));
        const unsavedGlobalStore = JSON.parse(localStorage.getItem("unsavedGlobalStore"));

        const incomeStore = useIncomeStore();
        const expenseStore = useExpenseStore();
        const savingStore = useSavingStore();
        const globalStore = useGlobalStore();

        if (unsavedIncomeList) {
          incomeStore.incomeList = unsavedIncomeList; // Restore the incomeList
          await incomeStore.saveIncomeToFirestore(user.uid); // Save to Firestore
          localStorage.removeItem("unsavedIncomeList"); // Clear localStorage
        }
        if (unsavedExpenseList) {
          expenseStore.expenseList = unsavedExpenseList; // Restore the expenseList
          await expenseStore.saveExpenseToFirestore(user.uid); // Save to Firestore
          localStorage.removeItem("unsavedExpenseList"); // Clear localStorage
        }
        if (unsavedSavingList) {
          savingStore.savingList = unsavedSavingList; // Restore the savingList
          await savingStore.saveSavingToFirestore(user.uid); // Save to Firestore
          localStorage.removeItem("unsavedSavingList"); // Clear localStorage
        }
        if (unsavedGlobalStore) {
          // Restore the globalStore data
          globalStore.gender = unsavedGlobalStore.gender;
          globalStore.genderIcon = unsavedGlobalStore.genderIcon;
          globalStore.spouseGender = unsavedGlobalStore.spouseGender;
          globalStore.spouseGenderIcon = unsavedGlobalStore.spouseGenderIcon;
          globalStore.currentAge = unsavedGlobalStore.currentAge;
          globalStore.retirementAge = unsavedGlobalStore.retirementAge;
          globalStore.livetillAge = unsavedGlobalStore.livetillAge;
          globalStore.spouseCurrentAge = unsavedGlobalStore.spouseCurrentAge;
          globalStore.spouseRetirementAge = unsavedGlobalStore.spouseRetirementAge;
          globalStore.spouseLiveTillAge = unsavedGlobalStore.spouseLiveTillAge;
          globalStore.inflationRate = unsavedGlobalStore.inflationRate;
          globalStore.returnRate = unsavedGlobalStore.returnRate;

          // Save globalStore data to Firestore
          await globalStore.saveGlobalStoreToFirestore(user.uid);
          localStorage.removeItem("unsavedGlobalStore"); // Clear localStorage
        }

        // Set the user in the Pinia store and redirect
        const userStore = useUserStore();
        userStore.setUser(user);
        this.$router.push(unsavedIncomeList || unsavedExpenseList || unsavedSavingList || unsavedGlobalStore ? '/Finance' : '/Setup');

      } catch (error) {
        // Handle errors, e.g., show error message to the user
        console.error("Error registering:", error.message);
      }
    },
    async signInWithGoogle() {
      try {
        const userCredential = await signInWithGoogle();
        const user = userCredential.user;

        // Save user to Firestore
        await addUserToFirestore(user.uid, { name: user.displayName, email: user.email });

        // Retrieve the temporarily saved incomeList from localStorage
        const unsavedIncomeList = JSON.parse(localStorage.getItem("unsavedIncomeList"));
        const unsavedExpenseList = JSON.parse(localStorage.getItem("unsavedExpenseList"));
        const unsavedSavingList = JSON.parse(localStorage.getItem("unsavedSavingList"));
        const unsavedGlobalStore = JSON.parse(localStorage.getItem("unsavedGlobalStore"));

        const incomeStore = useIncomeStore();
        const expenseStore = useExpenseStore();
        const savingStore = useSavingStore();
        const globalStore = useGlobalStore();

        if (unsavedIncomeList) {
          incomeStore.incomeList = unsavedIncomeList; // Restore the incomeList
          await incomeStore.saveIncomeToFirestore(user.uid); // Save to Firestore
          localStorage.removeItem("unsavedIncomeList"); // Clear localStorage
        }
        if (unsavedExpenseList) {
          expenseStore.expenseList = unsavedExpenseList; // Restore the expenseList
          await expenseStore.saveExpenseToFirestore(user.uid); // Save to Firestore
          localStorage.removeItem("unsavedExpenseList"); // Clear localStorage
        }
        if (unsavedSavingList) {
          savingStore.savingList = unsavedSavingList; // Restore the savingList
          await savingStore.saveSavingToFirestore(user.uid); // Save to Firestore
          localStorage.removeItem("unsavedSavingList"); // Clear localStorage
        }
        if (unsavedGlobalStore) {
          // Restore the globalStore data
          globalStore.gender = unsavedGlobalStore.gender;
          globalStore.genderIcon = unsavedGlobalStore.genderIcon;
          globalStore.spouseGender = unsavedGlobalStore.spouseGender;
          globalStore.spouseGenderIcon = unsavedGlobalStore.spouseGenderIcon;
          globalStore.currentAge = unsavedGlobalStore.currentAge;
          globalStore.retirementAge = unsavedGlobalStore.retirementAge;
          globalStore.livetillAge = unsavedGlobalStore.livetillAge;
          globalStore.spouseCurrentAge = unsavedGlobalStore.spouseCurrentAge;
          globalStore.spouseRetirementAge = unsavedGlobalStore.spouseRetirementAge;
          globalStore.spouseLiveTillAge = unsavedGlobalStore.spouseLiveTillAge;
          globalStore.inflationRate = unsavedGlobalStore.inflationRate;
          globalStore.returnRate = unsavedGlobalStore.returnRate;

          // Save globalStore data to Firestore
          await globalStore.saveGlobalStoreToFirestore(user.uid);
          localStorage.removeItem("unsavedGlobalStore"); // Clear localStorage
        }

        // Set the user in the Pinia store and redirect
        const userStore = useUserStore();
        userStore.setUser(user);
        this.$router.push(unsavedIncomeList || unsavedExpenseList || unsavedSavingList ? '/Finance' : '/Setup');

      } catch (error) {
        console.error("Error signing in with Google:", error.message);
      }
    },
    clearUser() {
      const userStore = useUserStore();
      userStore.clearUser();
    },
    gotoSignin() {
      this.$router.push('/signin');
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
